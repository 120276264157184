import React from 'react';
import { Link } from 'react-router-dom';
import chatIcon from '../assets/icon-chat.png';
import personIcon from '../assets/icon-person.png';
import dashboardIcon from '../assets/icon-dashboard.png';
import chartsIcon from '../assets/icon-charts.png';
import customIcon from '../assets/icon-custom.png';
import supportIcon from '../assets/icon-support.png';
import paymentsIcon from '../assets/icon-payments.png';
import './Sidebar.css';

const Sidebar = () => {
  return (
    <div className="sidebar">
      {/* Sección superior de iconos */}
      <div className="sidebar-top">
        <Link to="/messages" className="sidebar-icon" data-tooltip="Mensajes">
          <img src={chatIcon} alt="Chat" />
        </Link>
        <Link to="/" className="sidebar-icon" data-tooltip="Agentes">
          <img src={personIcon} alt="Agentes" />
        </Link>
        <Link to="coming-soon" className="sidebar-icon" data-tooltip="CRM">
          <img src={dashboardIcon} alt="Dashboard" />
        </Link>
        <Link to="/analytics" className="sidebar-icon" data-tooltip="Analíticas">
          <img src={chartsIcon} alt="Charts" />
        </Link>
      </div>

      {/* Sección inferior de iconos */}
      <div className="sidebar-bottom">
        {/* <Link to="" className="sidebar-icon" data-tooltip="Integraciones">
          <img src={customIcon} alt="Custom" />
        </Link> */}
        <Link to="/support" className="sidebar-icon" data-tooltip="Soporte">
          <img src={supportIcon} alt="Support" />
        </Link>
        <Link to="/profile" className="sidebar-icon" data-tooltip="Pagos">
          <img src={paymentsIcon} alt="Payments" />
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
