import React from 'react';
import './Support.css'; // Importa los estilos específicos

const Support = () => {
  return (
    <div className="coming-soon-container">
      <h1 className="coming-soon-text">Próximamente</h1>
    </div>
  );
};

export default Support;
