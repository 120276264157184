// src/context/SelectedAgentContext.js

import React, { createContext, useState } from 'react';

export const SelectedAgentContext = createContext();

export const SelectedAgentProvider = ({ children }) => {
  const [selectedAgent, setSelectedAgent] = useState(null);

  return (
    <SelectedAgentContext.Provider value={{ selectedAgent, setSelectedAgent }}>
      {children}
    </SelectedAgentContext.Provider>
  );
};
 