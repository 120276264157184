// src/components/CompleteSignUp.js

import React, { useState } from 'react';
import { getAuth, confirmPasswordReset } from 'firebase/auth';
import { useLocation } from 'react-router-dom';

const CompleteSignUp = () => {
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const location = useLocation();
  const auth = getAuth();

  // Extraer el oobCode de la URL
  const query = new URLSearchParams(location.search);
  const oobCode = query.get('oobCode');

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await confirmPasswordReset(auth, oobCode, password);
      setMessage('Tu contraseña ha sido establecida exitosamente. Ahora puedes iniciar sesión.');
    } catch (error) {
      setMessage(`Error: ${error.message}`);
    }
  };

  return (
    <div>
      <h1>Establece tu Contraseña</h1>
      {message && <p>{message}</p>}
      <form onSubmit={handleSubmit}>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Ingresa tu nueva contraseña"
          required
        />
        <button type="submit">Establecer Contraseña</button>
      </form>
    </div>
  );
};

export default CompleteSignUp;
