// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';

// Configuración de Firebase para "the-thing-mkt"
const firebaseConfig = {
  apiKey: "AIzaSyAEHDQWS2oCP9QXYxpBH9u0JpV3KYRDSi8",
  authDomain: "the-thing-mkt.firebaseapp.com",
  projectId: "the-thing-mkt",
  storageBucket: "the-thing-mkt.appspot.com",
  messagingSenderId: "36601006532",
  appId: "1:36601006532:web:9427adb747e4d28be03c91"
};

// Inicializa la aplicación de Firebase (app por defecto)
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
 