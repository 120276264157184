import React, { useEffect } from 'react';
import './Analytics.css'; // Importa los estilos específicos
import * as d3 from 'd3';

const Analytics = () => {
  useEffect(() => {
    // Crear gráficos en diferentes contenedores
    createLineChart('#chart1');
    createBarChart('#chart2');
    createDoughnutChart('#chart3');
    createStackedBarChart('#chart4');
  }, []);

  const createLineChart = (selector) => {
    d3.select(selector).selectAll('*').remove();
    const width = 400;
    const height = 300;

    const svg = d3
      .select(selector)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(20,20)');

    const data = [10, 20, 40, 80, 160, 200, 220, 240, 260, 300];

    const xScale = d3.scaleLinear().domain([0, data.length - 1]).range([0, width - 40]);
    const yScale = d3.scaleLinear().domain([0, d3.max(data)]).range([height - 40, 0]);

    const line = d3
      .line()
      .x((d, i) => xScale(i))
      .y((d) => yScale(d))
      .curve(d3.curveBasis);

    svg.append('path')
      .datum(data)
      .attr('fill', 'none')
      .attr('stroke', '#A55FFC')
      .attr('stroke-width', 3)
      .attr('d', line)
      .attr('stroke-dasharray', function () {
        const length = this.getTotalLength();
        return `${length} ${length}`;
      })
      .attr('stroke-dashoffset', function () {
        return this.getTotalLength();
      })
      .transition()
      .duration(2000)
      .ease(d3.easeCubic)
      .attr('stroke-dashoffset', 0);
  };

  const createBarChart = (selector) => {
    d3.select(selector).selectAll('*').remove();
    const width = 350;
    const height = 200;

    const svg = d3
      .select(selector)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(20,20)');

    const data = [15, 30, 45, 60, 20, 80, 40];

    const xScale = d3.scaleBand().domain(data.map((_, i) => i)).range([0, width - 40]).padding(0.2);
    const yScale = d3.scaleLinear().domain([0, d3.max(data)]).range([height - 40, 0]);

    svg.selectAll('.bar')
      .data(data)
      .enter()
      .append('rect')
      .attr('class', 'bar')
      .attr('x', (_, i) => xScale(i))
      .attr('y', (d) => yScale(d))
      .attr('width', xScale.bandwidth())
      .attr('height', (d) => height - 40 - yScale(d))
      .attr('fill', 'url(#gradient-bar)')
      .attr('opacity', 0)
      .transition()
      .duration(2000)
      .attr('opacity', 1);

    // Añadir degradado
    const defs = svg.append('defs');
    const gradient = defs.append('linearGradient')
      .attr('id', 'gradient-bar')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
    gradient.append('stop').attr('offset', '0%').attr('stop-color', '#9664ed');
    gradient.append('stop').attr('offset', '100%').attr('stop-color', '#5e3c99');
  };

  const createDoughnutChart = (selector) => {
    d3.select(selector).selectAll('*').remove();
    const width = 400;
    const height = 250;
    const radius = Math.min(width, height) / 2;

    const svg = d3
      .select(selector)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', `translate(${width / 2}, ${height / 2})`);

    const data = [30, 70, 50, 40];

    const color = d3.scaleOrdinal().range(['#774EBE', '#633FA0', '#4F3583', '#411E74']);

    const pie = d3.pie();
    const arc = d3.arc().innerRadius(radius / 2).outerRadius(radius);

    svg.selectAll('path')
      .data(pie(data))
      .enter()
      .append('path')
      .attr('d', arc)
      .attr('fill', (d, i) => color(i))
      .attr('opacity', 0)
      .transition()
      .duration(2000)
      .attr('opacity', 1);
  };

  const createStackedBarChart = (selector) => {
    d3.select(selector).selectAll('*').remove();
    const width = 450;
    const height = 280;
  
    // Crear SVG
    const svg = d3
      .select(selector)
      .append('svg')
      .attr('width', width)
      .attr('height', height)
      .append('g')
      .attr('transform', 'translate(30,30)');
  
    // Datos para el gráfico
    const data = [
      { group: 'A', value1: 30, value2: 70 },
      { group: 'B', value1: 50, value2: 20 },
      { group: 'C', value1: 80, value2: 50 },
    ];
  
    // Crear escalas
    const xScale = d3.scaleBand().domain(data.map((d) => d.group)).range([0, width - 60]).padding(0.2);
    const yScale = d3.scaleLinear().domain([0, 150]).range([height - 60, 0]);
  
    // Agregar ejes
    svg.append('g').call(d3.axisLeft(yScale));
    svg.append('g').attr('transform', `translate(0, ${height - 60})`).call(d3.axisBottom(xScale));
  
    // Definir degradado
    const defs = svg.append('defs');
    const gradient = defs.append('linearGradient')
      .attr('id', 'stacked-bar-gradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '0%')
      .attr('y2', '100%');
  
    gradient.append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#411E74'); // Violeta oscuro
  
    gradient.append('stop')
      .attr('offset', '80%')
      .attr('stop-color', '#62549F'); // Violeta más claro
  
    // Dibujar barras apiladas
    const keys = ['value1', 'value2'];
  
    svg.append('g')
      .selectAll('g')
      .data(d3.stack().keys(keys)(data))
      .enter()
      .append('g')
      .attr('fill', 'url(#stacked-bar-gradient)') // Aplicar el degradado a las barras
      .selectAll('rect')
      .data((d) => d)
      .enter()
      .append('rect')
      .attr('x', (d) => xScale(d.data.group))
      .attr('y', (d) => yScale(d[1]))
      .attr('height', (d) => yScale(d[0]) - yScale(d[1]))
      .attr('width', xScale.bandwidth())
      .attr('opacity', 0)
      .transition()
      .duration(2000)
      .attr('opacity', 1);
  };
  
  return (
    <div className="analytics-container">
      <div className="analytics-grid">
        <div className="analytics-card large-card">
          <div id="chart1" className="analytics-card-body"></div>
        </div>
        <div className="analytics-card small-card">
          <div id="chart2" className="analytics-card-body"></div>
        </div>
        <div className="analytics-card medium-card">
          <div id="chart3" className="analytics-card-body"></div>
        </div>
        <div className="analytics-card tall-card">
          <div id="chart4" className="analytics-card-body"></div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
