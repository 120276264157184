import React, { useState, useEffect, useRef } from 'react';
import { sendMessage } from '../services/openaiService';
import './ChatBubbleFrontend.css';
import { useParams } from 'react-router-dom';
import backgroundImage from '../assets/background-bubble.png';
import theThingChatBubble from '../assets/Burbuja-chat-predeterminada-violeta.png';
import PaperPlane from '../assets/paper-clip-button.png';
import thethingLogo from '../assets/Burbuja-chat-predeterminada-violeta.png';
import WhatsAppIcon from '../assets/WhatsApp_icon.png';

// Importa las funciones necesarias de Firebase y la instancia de Firestore
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

// Importar FontAwesomeIcon y el icono faComments
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';

export const ChatBubbleFrontend = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isChatOpen, setIsChatOpen] = useState(false); // Estado para la ventana de chat
  const [hideButtons, setHideButtons] = useState(false); // Estado para manejar la animación de salida
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState('');
  const [assistantDetails, setAssistantDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetchingDetails, setIsFetchingDetails] = useState(true);
  const { assistantId } = useParams();
  const [threadId, setThreadId] = useState(null);
  const messagesEndRef = useRef(null);

  useEffect(() => {
    const fetchAssistant = async () => {
      if (assistantId) {
        try {
          const docRef = doc(db, 'assistants', assistantId);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const details = docSnap.data();
            setAssistantDetails(details);
          } else {
            console.error('No se encontró el asistente con ID:', assistantId);
            setAssistantDetails(null);
          }
        } catch (error) {
          console.error('Error cargando los detalles del asistente:', error);
          setAssistantDetails(null);
        } finally {
          setIsFetchingDetails(false);
        }
      } else {
        console.error('assistantId no está definido en ChatBubble');
        setIsFetchingDetails(false);
      }
    };
    fetchAssistant();
  }, [assistantId]);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const formatMessageContent = (content) => {
    if (typeof content !== 'string') {
      console.error('formatMessageContent recibió un contenido que no es una cadena:', content);
      content = String(content);
    }
    const cleanedContent = content.replace(/【\d+:\d+†.*?】/g, '');
    const formattedContent = cleanedContent
      .replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>')
      .replace(/###\s*(.*?)(\n|$)/g, '<h3>$1</h3>')
      .replace(/\n/g, '<br>');
    return formattedContent;
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (inputMessage.trim() !== '') {
      const newMessages = [...messages, { text: inputMessage, sender: 'user' }];
      setMessages(newMessages);
      setInputMessage('');
      setIsLoading(true);

      try {
        const assistantResponse = await sendMessage(inputMessage, assistantId, threadId, newMessages);
        if (!threadId && assistantResponse?.thread_id) {
          setThreadId(assistantResponse.thread_id);
        }
        const assistantMessage = assistantResponse?.content || 'No se recibió una respuesta válida.';
        setMessages([...newMessages, { text: assistantMessage, sender: 'assistant' }]);
      } catch (error) {
        console.error('Error al enviar mensaje:', error);
        setMessages([...newMessages, { text: 'Error al procesar tu mensaje.', sender: 'assistant' }]);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const toggleChat = () => {
    if (!isOpen) {
      setIsOpen(true);
    } else {
      setHideButtons(true);
      setTimeout(() => {
        setIsOpen(false);
        setHideButtons(false);
      }, 500); // Duración de la animación en milisegundos
    }
  };

  const openChatWindow = () => {
    // Iniciar la animación de fade-out
    setHideButtons(true);

    // Después de la duración de la animación, mostrar la ventana de chat y ocultar los botones
    setTimeout(() => {
      setIsChatOpen(true);
      setIsOpen(false); // Cerrar los botones
      setHideButtons(false); // Resetear para la próxima vez
    }, 500); // Duración de la animación en milisegundos
  };

  const closeChatWindow = () => {
    // Opcional: Agregar una animación de fade-out para la ventana de chat
    // Para simplificar, simplemente cerramos la ventana
    setIsChatOpen(false);
    setIsOpen(false);
  };

  return (
    <div className="assistants-page">
      <div className="chat-bubble-container">
        <div className="chat-buttons-wrapper">
          <button
            onClick={toggleChat}
            className="chat-bubble-button-frontend"
            aria-label="Abrir opciones de chat"
          >
            <span className="wave wave1"></span>
            <span className="wave wave2"></span>
            <span className="wave wave3"></span>
            <span className="wave wave4"></span>
            <span className="wave wave5"></span>
            <img src={theThingChatBubble} alt="Chat" className="chat-bubble-image-frontend" />
          </button>
        </div>

        {/* Mostrar los botones cuando isOpen es true y el chat no está abierto */}
        {isOpen && !isChatOpen && (
          <>
            {/* Botón de FontAwesome */}
            <button
              className={`chat-icon-button ${hideButtons ? 'fade-out-right' : 'fade-in-right'}`}
              onClick={(e) => {
                e.stopPropagation();
                openChatWindow();
              }}
              aria-label="Abrir chat"
            >
              <FontAwesomeIcon icon={faComments} className="chat-icon" />
            </button>

            {/* Mostrar botón de WhatsApp solo si hay número */}
            {isFetchingDetails ? (
              <div className="whatsapp-bubble-large loading"></div>
            ) : assistantDetails && assistantDetails.phone_number ? (
              <button
                className={`whatsapp-bubble-large ${hideButtons ? 'fade-out-right' : 'fade-in-right'}`}
                onClick={(e) => {
                  e.stopPropagation();
                  const phoneNumber = assistantDetails.phone_number.replace(/\D/g, '');
                  window.open(`https://wa.me/${phoneNumber}`, '_blank');
                }}
                aria-label="Abrir chat de WhatsApp"
              >
                <img src={WhatsAppIcon} alt="WhatsApp" className="whatsapp-icon-large" />
              </button>
            ) : null /* No renderizar nada si no hay número de WhatsApp */}
          </>
        )}

        {/* Ventana de Chat */}
        {isChatOpen && (
          <div
            className="chat-window-frontend fade-in" // Añadir la clase 'fade-in' aquí
            style={{
              backgroundImage: `url(${backgroundImage})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="chat-header-bubble">
              <h3 className="h3-bubble-frontend">{assistantDetails ? assistantDetails.name : 'Chat'}</h3>
              <button onClick={closeChatWindow} className="close-button-bubble" aria-label="Cerrar chat">
                x
              </button>
            </div>

            <div className="chat-messages-bubble-frontend">
              {messages.map((msg, index) => (
                <div key={index} className={`message ${msg.sender}`}>
                  <span
                    className="message-content-bubble-frontend"
                    dangerouslySetInnerHTML={{ __html: formatMessageContent(msg.text) }}
                  />
                </div>
              ))}
              {isLoading && (
                <div className="message assistant">
                  <span className="message-content-bubble-frontend">...</span>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            <div className="chat-input-container-bubble-frontend">
              <input
                type="text"
                value={inputMessage}
                onChange={(e) => setInputMessage(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSendMessage(e);
                  }
                }}
                placeholder="Escribe tu mensaje..."
                className="chat-input-bubble-frontend"
                disabled={isLoading}
              />
              <button
                type="button"
                className="send-button-bubble-frontend"
                onClick={handleSendMessage}
                disabled={isLoading}
              >
                <img src={PaperPlane} alt="Enviar" className="send-button-img-bubble-frontend" />
              </button>
            </div>

            <div className="watermark">
              <span className="powered-by">Desarrollado por </span>
              <a
                href="https://thethingapp.com/"
                target="_blank"
                rel="noopener noreferrer"
                className="watermark-link"
              >
                <img src={thethingLogo} alt="The Thing" className="watermark-logo" />
                The Thing
              </a>
            </div>
          </div>
        )} 
      </div>
    </div>
  );
};
