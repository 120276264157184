// src/context/ApiKeyContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Asegúrate de tener la configuración correcta
import { doc, getDoc } from 'firebase/firestore';

// Crear el contexto
export const ApiKeyContext = createContext();

// Crear el proveedor del contexto
export const ApiKeyProvider = ({ children }) => {
  const [apiKey, setApiKey] = useState(localStorage.getItem('API_KEY') || '');
  const [loading, setLoading] = useState(true); // Estado para indicar si está cargando

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const data = userDoc.data();
            if (data.API_KEY) {
              setApiKey(data.API_KEY);
              localStorage.setItem('API_KEY', data.API_KEY);
            } else {
              console.error("Este usuario no tiene una API_KEY registrada.");
              setApiKey('');
              localStorage.removeItem('API_KEY');
            }
          } else {
            console.error("No se encontró el documento del usuario.");
            setApiKey('');
            localStorage.removeItem('API_KEY');
          }
        } catch (error) {
          console.error("Error obteniendo la API_KEY del usuario:", error);
          setApiKey('');
          localStorage.removeItem('API_KEY');
        }
      } else {
        // Usuario ha cerrado sesión
        setApiKey('');
        localStorage.removeItem('API_KEY');
      }
      setLoading(false); // Finalizar la carga
    });

    return () => unsubscribe();
  }, []);

  return (
    <ApiKeyContext.Provider value={{ apiKey, loading }}>
      {children}
    </ApiKeyContext.Provider>
  );
};
