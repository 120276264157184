// src/App.js

import React, { useContext, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import AssistantsPage from './components/AssistantsPage';
import AssistantChat from './components/AssistantChat';
import './App.css';
import { ChatBubbleFrontend } from './components/ChatBubbleFrontend';
import Register from './components/Register';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import { AuthProvider } from './context/AuthContext';
import SolicitarAcceso from './components/SolicitarAcceso';
import { ApiKeyProvider } from './context/ApiKeyContext';
import TemplatesPage from './components/Templates';
import ThreadsViewer from './components/ThreadsViewer';
import Header from './components/Header'; 
import Sidebar from './components/Sidebar';
import WideSidebar from './components/WideSidebar';
import { ThemeProvider, ThemeContext } from './context/ThemeContext';
import MisAsistentes from './components/MisAsistentes';
import backgroundApp from './assets/background-app.webp'; 
import PrivacyPolicy from './PrivacyPolicy';
import ServiceConditions from './ServiceConditions';
import { AssistantProvider } from './context/AssistantContext'; 
import { SelectedAgentProvider } from './context/SelectedAgentContext';
import CompleteSignUp from './components/CompleteSignUp';
import ComingSoon from './components/ComingSoon';
import Profile from './components/Profile';
import Analytics from './components/Analytics';
import Support from './components/Support';
import { SubscriptionProvider } from './context/SubscriptionContext';
import SubscriptionExpiredModal from './components/SubscriptionExpiredModal';
import ResetPassword from './components/ResetPassword';
import HandleAction from './components/HandleAction';

function AppContent() {
  const { isLightMode } = useContext(ThemeContext);
  const location = useLocation();

  // Identificar páginas de autenticación:
  const isAuthPage = ['/login', '/reset-password', '/handle-action'].includes(location.pathname);

  // Identificar si es página de mensajes/chat para ajustar padding:
  const isThreadsPage = location.pathname.startsWith('/messages') 
    || location.pathname.startsWith('/chat/') 
    || location.pathname === '/support';

  // Estado sidebar ancho:
  const [isWideSidebarVisible, setWideSidebarVisible] = useState(true);
  const toggleWideSidebar = () => {
    if (!isAuthPage) {
      setWideSidebarVisible(!isWideSidebarVisible);
    }
  };

  let appClassName = 'App';
  let appStyle = {};

  // Aplicar estilo login-register si es página auth:
  if (isAuthPage) {
    appClassName += ' login-register';
    appStyle = {
      backgroundImage: `url(${backgroundApp})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
    };
  } else {
    appStyle = {
      backgroundImage: `url(${backgroundApp})`,
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      minHeight: '100vh',
    };
  }

  const contentContainerClass = isThreadsPage ? 'content-container no-padding' : 'content-container';

  return (
    <div className={`${appClassName} ${!isAuthPage && isWideSidebarVisible ? 'wide-sidebar-visible' : ''} the-thing-global-text-style`} style={appStyle}>
      <AssistantProvider>
        <SelectedAgentProvider>
          <AuthProvider>
            <ApiKeyProvider>
              <SubscriptionExpiredModal />

              {/* Header y Sidebars solo si no es página auth */}
              {!isAuthPage && (
                <>
                  <Header toggleWideSidebar={toggleWideSidebar} isWideSidebarVisible={isWideSidebarVisible} />
                  <Sidebar />
                  <WideSidebar isVisible={isWideSidebarVisible} />
                </>
              )}

              {isAuthPage ? (
                // Rutas de autenticación (sin content-container)
                <Routes>
                  <Route path="/login" element={<Login />} />
                  <Route path="/reset-password" element={<ResetPassword />} />
                  <Route path="/handle-action" element={<HandleAction />} />
                </Routes>
              ) : (
                // Rutas no auth (envueltas en content-container)
                <div className={contentContainerClass}>
                  <Routes>
                    <Route path="/" element={<ProtectedRoute element={<MisAsistentes isWideSidebarVisible={isWideSidebarVisible} />} />} />
                    <Route path="/chat/:assistantId" element={<ProtectedRoute element={<AssistantChat />} />} />
                    <Route path="/bubble/" element={<ProtectedRoute element={<ChatBubbleFrontend />} />} />
                    <Route path="/bubble/:assistantId" element={<ProtectedRoute element={<ChatBubbleFrontend />} />} />
                    <Route path="/solicitar-acceso" element={<SolicitarAcceso />} />
                    <Route path="/templates" element={<ProtectedRoute element={<TemplatesPage />} />} />
                    <Route path="/messages" element={<ProtectedRoute element={<ThreadsViewer />} />} />
                    <Route path="/messages/:assistantId" element={<ProtectedRoute element={<ThreadsViewer />} />} />
                    <Route path="/profile" element={<ProtectedRoute element={<Profile />} />} />
                    <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route path="/service-conditions" element={<ServiceConditions />} />
                    <Route path="/completesignup" element={<CompleteSignUp />} />
                    <Route path="/coming-soon" element={<ComingSoon />} />
                    <Route path="/analytics" element={<ProtectedRoute element={<Analytics />} />} />
                    <Route path="/support" element={<ProtectedRoute element={<Support />} />} />
                  </Routes>
                </div>
              )}
            </ApiKeyProvider>
          </AuthProvider>
        </SelectedAgentProvider>
      </AssistantProvider>
    </div>
  );
}

function App() {
  return (
    <ThemeProvider>
      <Router>
        <AuthProvider>
          <SubscriptionProvider>
            <AppContent />
          </SubscriptionProvider>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
