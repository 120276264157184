import React, { useState } from 'react';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebase';
import './Auth.css'; // Reutilizamos los mismos estilos del login
import { Link } from 'react-router-dom';

const ResetPassword = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Se ha enviado un correo para restablecer tu contraseña. Por favor, revisa tu bandeja de entrada.');
    } catch (err) {
      if (err.code === 'auth/user-not-found') {
        setError('No se encontró una cuenta con este correo electrónico.');
      } else if (err.code === 'auth/invalid-email') {
        setError('Por favor, ingresa un correo válido.');
      } else {
        setError('Error al enviar el correo de restablecimiento: ' + err.message);
      }
    }
  };

  return (
    <div className="auth-page">
      <div className="auth-container">
        <h2>Recuperar Contraseña</h2>
        <form className="auth-form" onSubmit={handlePasswordReset}>
          <label htmlFor="email" className="auth-label">
            Correo Electrónico<span className="required">*</span>
          </label>
          <input
            id="email"
            className="auth-input"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Ingresa tu correo electrónico"
            required
          />
          <button type="submit" className="auth-button">Enviar Correo</button>
        </form>
        {message && <p className="success">{message}</p>}
        {error && <p className="error">{error}</p>}
        <p>
          <Link className="a" to="/login">Volver a Iniciar Sesión</Link>
        </p>
      </div>
    </div>
  );
};

export default ResetPassword;
