import React, { useState, useEffect } from 'react';
import { createAssistantInFirestore, createAssistantInOpenAI } from '../services/openaiService';
import { db } from '../firebase';
import { doc, setDoc, getDocs, collection, serverTimestamp } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';


const CreateAssistant = ({ prefilledData, onAssistantCreated, teamMembers = []  }) => { 
  const [name, setName] = useState('');
  const [instructions, setInstructions] = useState('');
  const navigate = useNavigate();

  const { currentUser } = useAuth();


  const saveAssistantImage = async (assistantId, image) => {
    const userDocRef = doc(db, 'assistants', assistantId);
    await setDoc(userDocRef, { image }, { merge: true });
  };



  const defaultInstructions = `1. Saludo Inicial: 
Saluda al usuario de manera amigable y profesional al iniciar la conversación. 
Ejemplo: “¡Hola! Soy Techi, tu asistente técnico. ¿En qué puedo ayudarte hoy?” 

2. Búsqueda de Respuestas: 
Busca respuestas en tu base de datos de información, incluyendo documentos PDF. 
Prioriza la búsqueda de información en estos documentos antes de ofrecer respuestas generales. 

3. Respuesta a Preguntas: 
Si encuentras una respuesta a la pregunta del usuario en los PDF, proporciónala al usuario de manera clara y concisa. 

4. Manejo de Respuestas No Encontradas: 
Si no puedes encontrar la respuesta en los PDF ni tampoco en tus instrucciones, indica la falta de información específica y ofrece una respuesta neutra y educada. 
Ejemplo: “Perdón, pero no tengo la respuesta exacta a esa pregunta. Te recomendaría agendar una consulta personalizada para poder resolver tus dudas puntuales” 

5. Cierre de la Conversación: 
Al finalizar la interacción, ofrece una despedida cortés y pregunta si el usuario necesita algo más. 
Ejemplo: “Si tienes más preguntas, no dudes en preguntar. ¡Estoy aquí para ayudar! Que tengas un gran día.”`;

const handleUseDefaultText = () => {
  setInstructions(defaultInstructions);
};



  useEffect(() => {
    if (prefilledData) {
      setName(prefilledData.name);
      setInstructions(prefilledData.instructions);
    }
  }, [prefilledData]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Asegurarse de que teamMembers es un array antes de llamar a .map
    const allowedUsers = Array.isArray(teamMembers) 
      ? teamMembers.map(member => member.id).concat(currentUser.uid) 
      : [currentUser.uid];

      const assistantData = {
        name,
        instructions,
        allowedUsers,
        model: 'gpt-4o-mini',
        tools: [],
        tool_resources: {},
        createdAt: serverTimestamp(), // Cambiar a createdAt
      };
      

    try {
      const newAssistant = await createAssistantInOpenAI(assistantData);

      if (newAssistant) {
        // Guardar las instrucciones del usuario en Firestore con creationDate
        await createAssistantInFirestore(assistantData, newAssistant.id);

        if (prefilledData && prefilledData.image) {
          await saveAssistantImage(newAssistant.id, prefilledData.image);
        }
        onAssistantCreated();
        navigate('/');
       
      } else {
      }
    } catch (error) {
      console.error('Error al crear el asistente:', error);
      alert('Error al crear el asistente.');
    }
  };

  return (
    <div>
      <h2 className='crear-asistente'>Nuevo Agente</h2>
      <p className='nombre-asistente'>Nombre del agente <span className="required">*</span></p>
      <form onSubmit={handleSubmit} className="auth-form">
        <input
          id="name"
          type="text" 
          value={name}
          onChange={(e) => setName(e.target.value)}
          placeholder="Agente de soporte técnico"
          className="crear-asistente-input"
          required
        />
        
         {/* Sección de Instrucciones con Botón */}
 
      <p className='nombre-asistente'>
        Instrucciones <span className="required">*</span>
      </p>
 

        <textarea
          className='instrucciones-textarea' 
          placeholder={`1. Saludo Inicial: 
Saluda al usuario de manera amigable y profesional al iniciar la conversación. 
Ejemplo: “¡Hola! Soy Techi, tu asistente técnico. ¿En qué puedo ayudarte hoy?” 

2. Búsqueda de Respuestas: 
Busca respuestas en tu base de datos de información, incluyendo documentos PDF. 
Prioriza la búsqueda de información en estos documentos antes de ofrecer respuestas generales. 

3. Respuesta a Preguntas: 
Si encuentras una respuesta a la pregunta del usuario en los PDF, proporciónala al usuario de manera clara y concisa. 

4. Manejo de Respuestas No Encontradas: 
Si no puedes encontrar la respuesta en los PDF ni tampoco en tus instrucciones, indica la falta de información específica y ofrece una respuesta neutra y educada. 
Ejemplo: “Perdón, pero no tengo la respuesta exacta a esa pregunta. Te recomendaría agendar una consulta personalizada para poder resolver tus dudas puntuales” 

5. Cierre de la Conversación: 
Al finalizar la interacción, ofrece una despedida cortés y pregunta si el usuario necesita algo más. 
Ejemplo: “Si tienes más preguntas, no dudes en preguntar. ¡Estoy aquí para ayudar! Que tengas un gran día.”`}
          value={instructions}
          onChange={(e) => setInstructions(e.target.value)}
          rows={10}
          style={{ width: '100%', textAlign: 'left' }} // Asegura que el texto esté alineado a la izquierda
          required
        />
      <div className="buttons-container">
      <button type="button" className="default-text-button" onClick={handleUseDefaultText}>
            Usar texto predeterminado
          </button>
          <button type="submit" className="create-button">Crear Agente</button>
        
        </div>
      </form>

    </div>
  );
};

export default CreateAssistant;
