import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext'; // Usamos el contexto de autenticación

const ProtectedRoute = ({ element }) => {
  const { currentUser } = useAuth();

  if (!currentUser) {
    // Si no hay usuario autenticado, redirigimos al registro
    return <Navigate to="/login" />;
  }

  return element;
};

export default ProtectedRoute;
