import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateAssistant from './CreateAssistant';
import CreateKnowledgeBase from './CreateKnowledgeBase';
import { getAllAssistants, getAllVectorStores } from '../services/openaiService';
import createAgentIcon from '../assets/create-new-agent.png';
import plusIcon from '../assets/+.png';
import './MisAsistentes.css';
import { useAuth } from '../context/AuthContext';

import { auth, db } from '../firebase'; // Asegúrate de exportar auth y db desde firebase.js
import { onAuthStateChanged } from 'firebase/auth';
import { collection, getDocs, writeBatch, doc, getDoc, query, where } from 'firebase/firestore';

// Definir los usuarios especiales que pueden crear múltiples asistentes
const SPECIAL_USERS = [
  '2sT3mjIH89NIxflXxUCX3KMoznv1', // hordonez@mktmarketingdigital.com
  'OmwFVKGeSgVbB155S7soMBUq2vw1', // alejandro@mktmarketingdigital.com
  'oo6SjpcCNCSJ6jrphZIIeQ8i9NI3', // omancuso@mktmarketingdigital.com
  'zrg727nMuRgIOSw2R8WGPtnoHyo1' // mkt@hotmail.com
];

const MisAsistentes = () => {
  const [assistants, setAssistants] = useState([]);
  const [knowledgeBases, setKnowledgeBases] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);
  const [showKnowledgeBaseForm, setShowKnowledgeBaseForm] = useState(false);
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');

  const { currentUser } = useAuth();
  // const [currentUserApiKey, setCurrentUserApiKey] = useState(''); // Se puede eliminar si no se usa en otros lugares
  const [teamMembers, setTeamMembers] = useState([]); // Estado para almacenar los miembros del equipo

  useEffect(() => {
    const initialize = async () => {
      if (currentUser) {
        await fetchTeamMembers(); // Obtener los miembros del equipo para todos los usuarios
        if (SPECIAL_USERS.includes(currentUser.uid)) {
          await updateKnowledgeBasesWithAdminUser();
        }
        await fetchAssistantsAndKnowledgeBases();
      }
    };
    initialize();
  }, [currentUser]);

  // Función para obtener los miembros del equipo basados en el accessCode
  const fetchTeamMembers = async () => {
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        const accessCode = userData.accessCode;

        if (accessCode) {
          const usersRef = collection(db, 'users');
          const q = query(usersRef, where('accessCode', '==', accessCode));
          const usersSnapshot = await getDocs(q);

          const teamMembersData = usersSnapshot.docs.map(docSnapshot => ({
            id: docSnapshot.id,
            ...docSnapshot.data(),
          })).filter(member => member.id !== currentUser.uid); // Excluir al usuario actual

          setTeamMembers(teamMembersData);
        }
      }
    } catch (error) {
      console.error('Error al obtener los miembros del equipo:', error);
    }
  };


// Función para actualizar allowedUsers en asistentes y bases de datos existentes
const updateAllowedUsers = async (assistantsData, vectorStoresData) => {
  try {
    const batch = writeBatch(db);
    const teamUids = teamMembers.map(member => member.id).concat(currentUser.uid); // Incluir al creador

    // Actualizar asistentes
    assistantsData.forEach(assistant => {
      const currentAllowed = assistant.allowedUsers || [];
      const missingUids = teamUids.filter(uid => !currentAllowed.includes(uid));
      if (missingUids.length > 0) {
        const newAllowed = [...currentAllowed, ...missingUids];
        const assistantRef = doc(db, 'assistants', assistant.id);
        batch.update(assistantRef, { allowedUsers: newAllowed });
      }
    });

    // Actualizar bases de datos
    vectorStoresData.forEach(kb => {
      const currentAllowed = kb.allowedUsers || [];
      const missingUids = teamUids.filter(uid => !currentAllowed.includes(uid));
      if (missingUids.length > 0) {
        const newAllowed = [...currentAllowed, ...missingUids];
        const kbRef = doc(db, 'bases', kb.id);
        batch.update(kbRef, { allowedUsers: newAllowed });
      }
    });

    await batch.commit();
    console.log('allowedUsers actualizado para asistentes y bases de datos existentes.');
  } catch (error) {
    console.error('Error al actualizar allowedUsers:', error);
  }
};

  // Función para actualizar todas las bases de conocimiento con el admin
  const updateKnowledgeBasesWithAdminUser = async () => {
    try {
      const basesRef = collection(db, 'bases');
      const basesSnapshot = await getDocs(basesRef);
      const batch = writeBatch(db);

      basesSnapshot.forEach((docSnapshot) => {
        const data = docSnapshot.data();
        let allowedUsers = data.allowedUsers || [];
        if (!allowedUsers.includes(currentUser.uid)) {
          allowedUsers.push(currentUser.uid);
          batch.update(docSnapshot.ref, { allowedUsers });
        }
      });

      await batch.commit();
      console.log('Usuario administrador agregado a todas las bases de conocimiento');
    } catch (error) {
      console.error('Error al actualizar las bases de conocimiento con el usuario administrador:', error);
    }
  };

// Función para obtener asistentes y bases de datos
const fetchAssistantsAndKnowledgeBases = async () => {
  if (!currentUser || !currentUser.uid) {
    console.log('currentUser.uid is undefined, esperando...');
    return;
  }

  setLoading(true);
  const assistantsData = await getAllAssistants(currentUser.uid);
  const vectorStoresData = await getAllVectorStores(currentUser.uid);
  setAssistants(assistantsData);
  setKnowledgeBases(vectorStoresData);

  // Actualizar allowedUsers para incluir a todos los miembros del equipo
  await updateAllowedUsers(assistantsData, vectorStoresData);

  setLoading(false);
};


  const handleAssistantCreated = (assistantData) => { 
    setShowCreateForm(false);
    fetchAssistantsAndKnowledgeBases();
  };

  const handleKnowledgeBaseCreated = (knowledgeBase) => {
    setShowKnowledgeBaseForm(false);
    setSelectedKnowledgeBase(null);
    fetchAssistantsAndKnowledgeBases();
  };

  const openNewKnowledgeBaseModal = () => {
    setSelectedKnowledgeBase(null);
    setShowKnowledgeBaseForm(true);
  };

  const openNewAgentModal = () => {
    // Reemplazar la lógica de la API key especial con la verificación de usuario especial
    if (
      SPECIAL_USERS.includes(currentUser.uid) || // Usuario especial puede crear múltiples asistentes
      assistants.length < 1 // Usuarios normales solo pueden crear un asistente
    ) {
      setShowCreateForm(true);
      setErrorMessage('');
    } else {
      setErrorMessage('Has alcanzado el límite de agentes permitidos. Por favor, elimina uno antes de crear otro.');
    }
  };

  const openKnowledgeBaseEdit = (knowledgeBase) => {
    setSelectedKnowledgeBase(knowledgeBase);
    setShowKnowledgeBaseForm(true);
  };

  const openChat = (assistantId) => {
    navigate(`/chat/${assistantId}`);
  };

  const renderAssistantCard = (assistant) => (
    <div key={assistant.id} className="assistant-card">
      <p>{assistant.name}</p>
      
      {/* Botón de Editar */}
      <button className="edit-button" onClick={(e) => {
        e.stopPropagation();
        navigate(`/chat/${assistant.id}`);
      }}>
        Editar
      </button>
    </div>
  );

  const renderKnowledgeBaseCard = (kb) => (
    <div key={kb.id} className="assistant-card">
      <p>{kb.name}</p>
      
      {/* Botón de Editar */}
      <button className="edit-button" onClick={(e) => {
        e.stopPropagation();
        openKnowledgeBaseEdit(kb);
      }}>
        Editar
      </button>
    </div>
  );

  /*
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        setUserUid(user.uid);
        try {
          const userDocRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userDocRef);
          if (userDoc.exists()) {
            const data = userDoc.data();
            setCurrentUserApiKey(data.API_KEY || data.apiKey || '');
          } else {
            console.error('No existe el documento del usuario en Firestore');
          }
        } catch (error) {
          console.error('Error al obtener el documento del usuario:', error);
        }
      } else {
        setUserUid(null);
        setCurrentUserApiKey('');
      }
    });
  
    return () => unsubscribe();
  }, []);
  */

  return (
    <div className="assistants-page">
      <div className="center-content">
        <h2 id="misagentes" className="mis-agentes-texto">Mis agentes</h2> {/* ID agregado aquí */}
        <div className="section-container">
          <div onClick={openNewAgentModal} className="create-card">
            <p className="create-text">+Crear nuevo agente</p>
            <img src={plusIcon} alt="Plus Icon" className="plus-icon" />
          </div>

          {showCreateForm && (
            <div className="modal-background">
              <div className="modal-creator-content">
                <button onClick={() => setShowCreateForm(false)} className="close-button">X</button>
                <CreateAssistant onAssistantCreated={handleAssistantCreated} />
              </div>
            </div>
          )}

          {!loading && assistants.length > 0 && assistants.map(renderAssistantCard)}
        </div>
        {errorMessage && (
          <div className="error-message">
            {errorMessage}
          </div>
        )}
        <h2 id="basesdedatos" className="mis-agentes-texto">Mis Bases de datos</h2> {/* ID agregado aquí */}
        <div className="section-container">
          <div onClick={openNewKnowledgeBaseModal} className="create-card">
            <p>+Crear base de datos</p>
            <img src={plusIcon} alt="Plus Icon" className="plus-icon" />
          </div>

          {showKnowledgeBaseForm && (
            <div className="modal-background">
              <div className="modal-base-conocimiento">
                <button onClick={() => setShowKnowledgeBaseForm(false)} className="close-button">X</button>
                <CreateKnowledgeBase
                  onKnowledgeBaseCreated={handleKnowledgeBaseCreated}
                  knowledgeBaseToEdit={selectedKnowledgeBase}
                />
              </div>
            </div>
          )}

          {!loading && knowledgeBases.length > 0 && knowledgeBases.map(renderKnowledgeBaseCard)}
        </div>
      </div>
    </div>
  );
};

export default MisAsistentes;
