// src/components/WideSidebar.js

import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './WideSidebar.css';
import { db } from '../firebase';
import { collection, getDocs, onSnapshot } from 'firebase/firestore';
import { useAuth } from '../context/AuthContext';
import { getAllAssistants } from '../services/openaiService';



const WideSidebar = ({ isVisible }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser } = useAuth(); // Obtener el usuario actual

  // Verificar si la ruta actual es /threads o /threads/{assistantId}
  const isThreadsPage = location.pathname.startsWith('/messages');

  // Verificar si la ruta actual es /chat o /chat/{assistantId}
  const isChatPage = location.pathname.startsWith('/chat');

  // Estado para la búsqueda de agentes en /threads
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [assistants, setAssistants] = useState([]);
  const [loading, setLoading] = useState(true);
  const [assistantThreadCounts, setAssistantThreadCounts] = useState({});

  // Referencia para almacenar las funciones de limpieza de los listeners
  const unsubscribeThreadListenersRef = useRef([]);

  // Obtener la lista de asistentes al cargar el componente
  useEffect(() => {
    const fetchAssistants = async () => {
      if (currentUser && currentUser.uid) {
        setLoading(true);
        const assistantsData = await getAllAssistants(currentUser.uid);
        setAssistants(assistantsData);
        setLoading(false);
      }
    };

    fetchAssistants();
  }, [currentUser]);

  // Actualizar filteredAgents cuando cambian assistants o agentSearchTerm
  useEffect(() => {
    if (agentSearchTerm.trim() === '') {
      setFilteredAgents(assistants);
    } else {
      setFilteredAgents(
        assistants.filter((agent) =>
          agent.name.toLowerCase().includes(agentSearchTerm.toLowerCase())
        )
      );
    }
  }, [assistants, agentSearchTerm]);

  // Configurar listeners en tiempo real para los counts de threads de cada asistente
  useEffect(() => {
    const setupThreadCountListeners = () => {
      // Limpiar cualquier listener existente antes de configurar nuevos
      unsubscribeThreadListenersRef.current.forEach((unsubscribe) => unsubscribe());
      unsubscribeThreadListenersRef.current = [];

      const counts = {};

      assistants.forEach((assistant) => {
        const threadsCollectionRef = collection(db, `threads_${assistant.id}`);

        // Configurar el listener en tiempo real
        const unsubscribe = onSnapshot(
          threadsCollectionRef,
          (querySnapshot) => {
            // Actualizar el conteo de threads para este asistente
            counts[assistant.id] = querySnapshot.size;
            setAssistantThreadCounts((prevCounts) => ({
              ...prevCounts,
              [assistant.id]: querySnapshot.size,
            }));
          },
          (error) => {
            console.error(`Error en el listener de threads_${assistant.id}:`, error);
            // En caso de error, establecer el conteo a 0
            setAssistantThreadCounts((prevCounts) => ({
              ...prevCounts,
              [assistant.id]: 0,
            }));
          }
        );

        // Almacenar la función de limpieza
        unsubscribeThreadListenersRef.current.push(unsubscribe);
      });
    };

    if (isThreadsPage && assistants.length > 0) {
      setupThreadCountListeners();
    }

    // Limpieza de listeners al desmontar el componente o cuando assistants cambia
    return () => {
      unsubscribeThreadListenersRef.current.forEach((unsubscribe) => unsubscribe());
      unsubscribeThreadListenersRef.current = [];
    };
  }, [isThreadsPage, assistants]);

  // Función para manejar la búsqueda de agentes
  const handleAgentSearch = (term) => {
    setAgentSearchTerm(term);
  };

  // Función para seleccionar un agente desde /threads
  const handleSelectAgent = (agent) => {
    navigate(`/messages/${agent.id}`); // Navegar a la ruta con assistantId
  };

  // Función para seleccionar un agente desde /chat/:assistantId
  const handleChatAgentSelection = (assistantId) => {
    navigate(`/chat/${assistantId}`);
  };

  // Función para desplazarse a una sección específica
  const scrollToSection = (id) => {
    document.getElementById(id)?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div className={`wide-sidebar ${isVisible ? 'visible' : 'hidden'}`}>
      {isThreadsPage ? (
        <div className="wide-sidebar-content">
          <h2 className="titulos-threads">Mis agentes</h2>
          <div className="search-container">
            <input
              type="text"
              className="search-input"
              placeholder="Buscar"
              value={agentSearchTerm}
              onChange={(e) => handleAgentSearch(e.target.value)}
            />
          </div>
          <ul className="scrollable-list">
            {loading ? (
              <li className="list-item">Cargando...</li>
            ) : filteredAgents.length > 0 ? (
              filteredAgents.map((agent) => (
                <li
                  key={agent.id}
                  className="list-item"
                  onClick={() => handleSelectAgent(agent)}
                >
                  <div className="agent-info">
                    <p className="agent-name">{agent.name}</p>
                    <span className="thread-count">
                      {assistantThreadCounts[agent.id] || 0}
                    </span>
                  </div>
                </li>
              ))
            ) : (
              <li className="list-item">No se encontraron agentes</li>
            )}
          </ul>
        </div>
      ) : isChatPage ? (
        <div className="wide-sidebar-content">
          <h2>
            Entrenador
            <br /> de Agentes
          </h2>
          <ul className="scrollable-list">
            {loading ? (
              <li className="list-item">Cargando...</li>
            ) : assistants.length > 0 ? (
              assistants.map((assistant) => (
                <li
                  key={assistant.id}
                  className="list-item"
                  onClick={() => handleChatAgentSelection(assistant.id)}
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      handleChatAgentSelection(assistant.id);
                    }
                  }}
                >
                  {assistant.name}
                </li>
              ))
            ) : (
              <li className="list-item">No hay agentes disponibles</li>
            )}
          </ul>
        </div>
      ) : (
        <div className="wide-sidebar-content">
          <h2>
            Comenzar <br /> The Thing
          </h2>
          <ul className="scrollable-list">
            <li className="list-item" onClick={() => scrollToSection('misagentes')}>
              MIS AGENTES
            </li>
            <li className="list-item" onClick={() => scrollToSection('basesdedatos')}>
              MIS BASES DE DATOS
            </li>
            <li className="list-item" onClick={() => scrollToSection('tutorial')}>
              TUTORIAL
            </li>
            {/* Añade más <li> aquí si es necesario */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default WideSidebar;
