// src/components/Profile.js
import React, { useEffect, useState } from 'react';
import './Profile.css'; // Importa el archivo CSS actualizado
import { useAuth } from '../context/AuthContext';
import { collection, doc, getDoc, getDocs, query, where, setDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { auth } from '../firebase';

const Profile = () => {
  const { currentUser } = useAuth();
  const [userData, setUserData] = useState(null);
  const [teamMembers, setTeamMembers] = useState([]);
  const [isEditingName, setIsEditingName] = useState(false);
  const [newName, setNewName] = useState('');
  const [totalThreads, setTotalThreads] = useState(0);
  const [purchasedThreads, setPurchasedThreads] = useState(0); 
  const [remainingConversations, setRemainingConversations] = useState(200); 

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          let userData;

          if (!userDoc.exists()) {
            // Si el documento del usuario no existe, crearlo
            let displayName = auth.currentUser.displayName || 'Usuario de the thing';
            userData = {
              name: displayName,
              email: currentUser.email,
              API_KEY: '',
              totalThreads: 0,
              purchasedThreads: 0, // Inicializamos en 0
            };
            await setDoc(userDocRef, userData);
          } else {
            userData = userDoc.data();
            // Verificar si el campo 'name' existe
            if (!userData.name) {
              let displayName = auth.currentUser.displayName || 'Usuario de the thing';
              await updateDoc(userDocRef, { name: displayName });
              userData.name = displayName;
            }
            // Asegurar que purchasedThreads exista
            if (userData.purchasedThreads === undefined) {
              await updateDoc(userDocRef, { purchasedThreads: 0 });
              userData.purchasedThreads = 0;
            }
          }

          setUserData(userData);

          // Obtener totalThreads del usuario
          let storedTotalThreads = userData.totalThreads || 0;
          setTotalThreads(storedTotalThreads);

          // Obtener purchasedThreads del usuario
          let storedPurchasedThreads = userData.purchasedThreads || 0;
          setPurchasedThreads(storedPurchasedThreads);

          // Calcular conversaciones restantes gratuitas
          const remaining = Math.max(0, 200 - storedTotalThreads);
          setRemainingConversations(remaining);

          const accessCode = userData.accessCode; // Obtener el accessCode del usuario
          if (accessCode) {
            // Obtener usuarios con el mismo accessCode
            const usersRef = collection(db, 'users');
            const q = query(usersRef, where('accessCode', '==', accessCode));
            const usersSnapshot = await getDocs(q);
          
            const teamMembersData = usersSnapshot.docs
              .filter((docSnapshot) => docSnapshot.id !== currentUser.uid) 
              .map((docSnapshot) => ({
                id: docSnapshot.id,
                ...docSnapshot.data(),
              }));
          
            if (teamMembersData.length > 0) {
              setTeamMembers(teamMembersData);
            }
          }
          
        } catch (error) {
          console.error('Error al obtener datos del usuario:', error);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const getInitials = (name) => {
    if (!name) return '';
    const names = name.split(' ');
    const initials = names.map((n) => n.charAt(0).toUpperCase()).join('');
    return initials.substring(0, 2);
  };

  const handleEditName = () => {
    setNewName(userData.name);
    setIsEditingName(true);
  };

  const handleSaveName = async () => {
    try {
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, { name: newName });
      setUserData({ ...userData, name: newName });
      setIsEditingName(false);
      alert('Nombre actualizado con éxito.');
    } catch (error) {
      console.error('Error al actualizar el nombre:', error);
      alert('Error al actualizar el nombre.');
    }
  };

  const handleCancelEditName = () => {
    setNewName(userData.name);
    setIsEditingName(false);
  };

  const formatAccessExpiresAt = (timestamp) => {
    if (!timestamp) return 'No definido';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return date.toLocaleDateString('es-ES', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
  };

  return (
    <div className="profile-page">
      <div className="profile-page-container">
        <div className="profile-section-container">
          <div className="profile-assistant-editor-container">
            <h1 className="profile-title">Perfil</h1>
            {userData && (
              <div className="user-info">
                <div className="profile-picture">
                  <div className="initials-circle">
                    {getInitials(userData.name)}
                  </div>
                </div>
                <div className="user-details">
                  {isEditingName ? (
                    <div className="name-editor">
                      <input
                        type="text"
                        value={newName}
                        onChange={(e) => setNewName(e.target.value)}
                        className="new-name-input"
                      />
                      <button onClick={handleSaveName} className="save-newname-button">Guardar</button>
                      <button onClick={handleCancelEditName} className="cancel-edit-button">Cancelar</button>
                    </div>
                  ) : (
                    <div className="name-display">
                      <p className='name-user'><strong>Nombre:</strong> {userData.name}</p>
                      <button onClick={handleEditName} className="edit-name-button">Editar Nombre</button>
                    </div>
                  )}
                  <p className='name-user'> <strong>Email:</strong> {userData.email}</p>
                </div>
              </div>
            )}

            {teamMembers.length > 0 && (
              <>
                <h2 className="team-title">Mi Equipo</h2>
                <ul className="team-list">
                  {teamMembers.map((member) => (
                    <li key={member.id} className="team-member">
                      <div className="member-initials-circle">
                        {getInitials(member.name)}
                      </div>
                      <div className="member-details">
                        <p className='name-user'><strong>Nombre:</strong> {member.name}</p>
                        <p className='name-user'><strong>Email:</strong> {member.email}</p>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            )}
          </div>
        </div>

        <div className="profile-section-container">
          <div className="payments-container">
            <h1 className="payments-title">Mis consumos</h1>
            <div className="conversations-counter">
              <h2 className="conversations-title">Conversaciones gratuitas</h2>
              <p className="conversations-info">
                Has consumido <br />
                <span className='conversations-count'>{totalThreads}</span>
              </p>
              <p className="conversations-info-2">Te quedan {remainingConversations} conversaciones.</p>
            </div>

            <div className="conversations-counter">
              <h2 className="conversations-title">Conversaciones pagas</h2>
              <p className="conversations-count">{purchasedThreads}</p>
            </div>

            <div className="subscription-counter">
              <h2 className="subscription-title">Vencimiento de Suscripción</h2>
              <p className="subscription-date">{formatAccessExpiresAt(userData?.accessExpiresAt)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
