import React, { useState, useEffect, useRef } from 'react';
import './ThreadsViewer.css';
import whatsappLogo from '../assets/whatsapp-logo.png';
import paperClipButton from '../assets/paper-clip-button.png';
import ControlAgenteIA from '../assets/Control-Agente-IA.png';
import webChatIcon from '../assets/web-chat-icon.png';

import { useAuth } from '../context/AuthContext';
import { useNavigate, useParams } from 'react-router-dom';
import plusIconChat from '../assets/+-icon-chat.png';
import emojiIconChat from '../assets/emoji-icon-chat.png';
import clipIconChat from '../assets/clip-icon-chat.png';

import { getAllAssistants } from '../services/openaiService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar as faStarSolid,
  faCaretDown,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';

import { FaWhatsapp } from 'react-icons/fa';

import backgroundChat from '../assets/background-chat.png';

import { faStar as faStarRegular } from '@fortawesome/free-regular-svg-icons';

import { db } from '../firebase';
import {
  collection,
  query,
  onSnapshot,
  updateDoc,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
} from 'firebase/firestore';

const ThreadsViewer = () => {
  const [selectedAgent, setSelectedAgent] = useState(null);

  const [isOrderDropdownOpen, setIsOrderDropdownOpen] = useState(false);

  const [agents, setAgents] = useState([]);
  const [filteredAgents, setFilteredAgents] = useState([]);
  const [agentSearchTerm, setAgentSearchTerm] = useState('');
  const [threads, setThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const [loading, setLoading] = useState(true);
  const [newMessage, setNewMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredThreads, setFilteredThreads] = useState([]);
  const [isAutoResponseEnabled, setIsAutoResponseEnabled] = useState(true);
  const [scrollOnLoad, setScrollOnLoad] = useState(true);
  const [isUserNearBottom, setIsUserNearBottom] = useState(true);
  const [unreadThreadIds, setUnreadThreadIds] = useState([]);

  const messageHistoryRef = useRef(null);
  const prevThreadsRef = useRef([]);
  const orderFilterRef = useRef(null);

  const [showOnlyUnread, setShowOnlyUnread] = useState(false);

  const [favoriteThreadIds, setFavoriteThreadIds] = useState([]);
  const [orderBy, setOrderBy] = useState('lastMessage');

  const { assistantId } = useParams(); 
  const navigate = useNavigate(); 
  const { currentUser } = useAuth();

  // Estados para manejo de conversaciones restantes
  const [totalThreads, setTotalThreads] = useState(0);
  const [purchasedThreads, setPurchasedThreads] = useState(0);
  const [remainingConversations, setRemainingConversations] = useState(200);
  const [isLimitReached, setIsLimitReached] = useState(false);
  const [showConversationLimitModal, setShowConversationLimitModal] = useState(false);

  const handleOrderChange = (e) => {
    setOrderBy(e.target.value);
  };

  const getPastelColor = (text) => {
    let hash = 0;
    for (let i = 0; i < text.length; i++) {
      hash = text.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash; 
    }

    const hue = Math.abs(hash) % 360;
    const saturation = 40 + (Math.abs(hash) % 21);
    const lightness = 80 + (Math.abs(hash) % 6);

    const c = (1 - Math.abs((2 * lightness) / 100 - 1)) * (saturation / 100);
    const x = c * (1 - Math.abs(((hue / 60) % 2) - 1));
    const m = lightness / 100 - c / 2;
    let r, g, b;

    if (hue >= 0 && hue < 60) {
      r = c; g = x; b = 0;
    } else if (hue >= 60 && hue < 120) {
      r = x; g = c; b = 0;
    } else if (hue >= 120 && hue < 180) {
      r = 0; g = c; b = x;
    } else if (hue >= 180 && hue < 240) {
      r = 0; g = x; b = c;
    } else if (hue >= 240 && hue < 300) {
      r = x; g = 0; b = c;
    } else {
      r = c; g = 0; b = x;
    }

    const R = Math.round((r + m) * 255);
    const G = Math.round((g + m) * 255);
    const B = Math.round((b + m) * 255);

    return `rgba(${R}, ${G}, ${B}, 0.7)`;
  };

  const getInitials = (name) => {
    const namesArray = name.trim().split(' ');
    if (namesArray.length === 1) {
      return namesArray[0].charAt(0).toUpperCase();
    } else {
      return (
        namesArray[0].charAt(0).toUpperCase() +
        namesArray[namesArray.length - 1].charAt(0).toUpperCase()
      );
    }
  };

  useEffect(() => {
    if (!currentUser) return;
    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribeUser = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const updatedTotalThreads = userData.totalThreads || 0;
        const updatedPurchasedThreads = userData.purchasedThreads || 0;

        setTotalThreads(updatedTotalThreads);
        setPurchasedThreads(updatedPurchasedThreads);

        const remaining = Math.max(0, 200 + updatedPurchasedThreads - updatedTotalThreads);
        setRemainingConversations(remaining);

        if (remaining <= 0) {
          setIsLimitReached(true);
          setIsAutoResponseEnabled(false);
          setShowConversationLimitModal(true); // Mostrar modal al entrar
        } else {
          setIsLimitReached(false);
        }

      }
    }, (error) => {
      console.error('Error en listener del usuario:', error);
    });

    return () => {
      unsubscribeUser();
    };
  }, [currentUser]);


  useEffect(() => {
    const fetchAgentsAndSetSelected = async () => {
      if (!currentUser || !assistantId) {
        setLoading(false);
        return;
      }
  
      try {
        const assistantsData = await getAllAssistants(currentUser.uid);
        setAgents(assistantsData);
  
        const agent = assistantsData.find((a) => a.id === assistantId);
        if (agent) {
          setSelectedAgent(agent);
        } else {
          console.error('assistantId inválido o no pertenece al usuario actual');
          setSelectedAgent(null);
          setSelectedThread(null);
          navigate('/threads'); 
        }
      } catch (error) {
        console.error('Error al obtener agentes:', error);
      }
      setLoading(false);
    };
    
    fetchAgentsAndSetSelected();
  }, [assistantId, currentUser, navigate]);
  

  useEffect(() => {
    if (selectedAgent) {
      fetchThreadsByAssistantId(selectedAgent.id);
      setSelectedThread(null);
    } else {
      setThreads([]);
      setSelectedThread(null);
    }
  }, [selectedAgent]);

  const toggleFavorite = async (threadId) => {
    if (!selectedAgent) return;

    const threadRef = doc(db, `threads_${selectedAgent.id}`, threadId);

    try {
      const threadSnapshot = await getDoc(threadRef);
      if (threadSnapshot.exists()) {
        const currentIsFavorite = threadSnapshot.data().isFavorite || false;
        await updateDoc(threadRef, {
          isFavorite: !currentIsFavorite,
        });
      }
    } catch (error) {
      console.error('Error al actualizar favorito:', error);
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';
    const countryCode = phoneNumber.slice(0, 2);
    const areaCode = phoneNumber.slice(2, 3);
    const cityCode = phoneNumber.slice(3, 5);
    const firstPart = phoneNumber.slice(5, 9);
    const secondPart = phoneNumber.slice(9);
    return `+${countryCode} ${areaCode} ${cityCode} ${firstPart} ${secondPart}`;
  };

  useEffect(() => {
    if (isUserNearBottom && messageHistoryRef.current) {
      messageHistoryRef.current.scrollTop = messageHistoryRef.current.scrollHeight;
    }
  }, [selectedThread?.messageHistory]);

  useEffect(() => {
    const messageContainer = messageHistoryRef.current;
    if (!messageContainer) return;

    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } = messageContainer;
      const threshold = 10;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - threshold;
      setIsUserNearBottom(isAtBottom);
    };

    messageContainer.addEventListener('scroll', handleScroll);
    return () => {
      messageContainer.removeEventListener('scroll', handleScroll);
    };
  }, [messageHistoryRef]);

  useEffect(() => {
    if (scrollOnLoad && messageHistoryRef.current) {
      messageHistoryRef.current.scrollTop = messageHistoryRef.current.scrollHeight;
      setScrollOnLoad(false);
    }
  }, [selectedThread, scrollOnLoad]);

  useEffect(() => {
    setFilteredAgents(agents);
  }, [agents]);

  useEffect(() => {
    let sortedThreads = [...threads];

    if (searchTerm.trim() !== '') {
      sortedThreads = sortedThreads.filter((thread) =>
        thread.phoneNumber.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    if (showOnlyUnread) {
      sortedThreads = sortedThreads.filter((thread) =>
        unreadThreadIds.includes(thread.threadId)
      );
    }

    if (orderBy === 'name') {
      sortedThreads.sort((a, b) =>
        (a.senderName || '').localeCompare(b.senderName || '')
      );
    } else if (orderBy === 'date') {
      sortedThreads.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
    } else if (orderBy === 'lastMessage') {
      sortedThreads.sort((a, b) => {
        const aLastMessageDate = new Date(
          a.messageHistory[a.messageHistory.length - 1]?.createdAt
        );
        const bLastMessageDate = new Date(
          b.messageHistory[b.messageHistory.length - 1]?.createdAt
        );
        return bLastMessageDate - aLastMessageDate;
      });
    } else if (orderBy === 'read') {
      sortedThreads = sortedThreads.filter(
        (thread) => !unreadThreadIds.includes(thread.threadId)
      );
    } else if (orderBy === 'unread') {
      sortedThreads = sortedThreads.filter((thread) =>
        unreadThreadIds.includes(thread.threadId)
      );
    } else if (orderBy === 'favorites') {
      sortedThreads = sortedThreads.filter((thread) => thread.isFavorite);
    }

    setFilteredThreads(sortedThreads);

    threads.forEach((thread) => {
      if (thread.threadId !== selectedThread?.threadId) {
        const oldThread = prevThreadsRef.current.find(
          (t) => t.threadId === thread.threadId
        );
        if (
          oldThread &&
          thread.messageHistory.length > oldThread.messageHistory.length
        ) {
          setUnreadThreadIds((prevIds) =>
            prevIds.includes(thread.threadId)
              ? prevIds
              : [...prevIds, thread.threadId]
          );
        }
      }
    });

    prevThreadsRef.current = threads;
  }, [threads, searchTerm, selectedThread?.threadId, orderBy, showOnlyUnread, unreadThreadIds]);

  const fetchThreadsByAssistantId = async (assistantId) => {
    setLoading(true);
    try {
      const threadsCollection = collection(db, `threads_${assistantId}`);
      const q = query(threadsCollection);
      const querySnapshot = await getDocs(q);

      const threadsData = querySnapshot.docs.map((doc) => ({
        threadId: doc.id,
        ...doc.data(),
      }));

      const sortedThreads = threadsData.slice().sort((a, b) => {
        const aLastMessageDate = new Date(
          a.messageHistory[a.messageHistory.length - 1]?.createdAt
        );
        const bLastMessageDate = new Date(
          b.messageHistory[b.messageHistory.length - 1]?.createdAt
        );
        return bLastMessageDate - aLastMessageDate;
      });

      setThreads(sortedThreads);

      if (sortedThreads.length > 0) {
        setSelectedThread(sortedThreads[0]);
        setIsAutoResponseEnabled(sortedThreads[0].autoResponseEnabled);
        setScrollOnLoad(true);
        setIsUserNearBottom(true);
      } else {
        setSelectedThread(null);
      }
    } catch (error) {
      console.error('Error al obtener los threads del asistente:', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    let unsubscribe;
    if (selectedAgent) {
      const q = query(collection(db, `threads_${selectedAgent.id}`));
      unsubscribe = onSnapshot(q, (querySnapshot) => {
        const threadsData = querySnapshot.docs.map((doc) => ({
          threadId: doc.id,
          ...doc.data(),
        }));

        

        const sortedThreads = threadsData.slice().sort((a, b) => {
          const aLastMessageDate = new Date(
            a.messageHistory[a.messageHistory.length - 1]?.createdAt
          );
          const bLastMessageDate = new Date(
            b.messageHistory[b.messageHistory.length - 1]?.createdAt
          );
          return bLastMessageDate - aLastMessageDate;
        });

        setThreads(sortedThreads);
      });
    } else {
      setThreads([]);
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedAgent]);

  useEffect(() => {
    let unsubscribe;
    if (selectedThread && selectedAgent) {
      unsubscribe = onSnapshot(
        doc(db, `threads_${selectedAgent.id}`, selectedThread.threadId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            setSelectedThread({
              threadId: docSnapshot.id,
              ...docSnapshot.data(),
            });
          }
        }
      );
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [selectedThread?.threadId, selectedAgent]);

  const formatMessageContent = (content) => {
    let formattedContent = content;
    formattedContent = formattedContent.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
    formattedContent = formattedContent.replace(/\*(.*?)\*/g, '<strong>$1</strong>');
    formattedContent = formattedContent.replace(/^###\s*(.*)$/gm, '<h3>$1</h3>');
    formattedContent = formattedContent.replace(/^-\s(.*)$/gm, '<li>$1</li>');
    formattedContent = formattedContent.replace(/^\*\s(.*)$/gm, '<li>$1</li>');
    formattedContent = formattedContent.replace(/^\d+\.\s(.*)$/gm, '<li>$1</li>');
    formattedContent = formattedContent.replace(/<\/li>\n<li>/g, '</li><li>');
    formattedContent = formattedContent.replace(/(<li>.*<\/li>)/g, '<ul>$1</ul>');

    const urlRegex = /(https?:\/\/[^\s]+)/g;
    formattedContent = formattedContent.replace(urlRegex, '<a href="$1" target="_blank" rel="noopener noreferrer">$1</a>');

    formattedContent = formattedContent.replace(/\n/g, '<br>');
    return formattedContent;
  };

  const handleAgentSearch = (term) => {
    setAgentSearchTerm(term);
    if (term.trim() === '') {
      setFilteredAgents(agents);
    } else {
      setFilteredAgents(
        agents.filter((agent) =>
          agent.name.toLowerCase().includes(term.toLowerCase())
        )
      );
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
  };

  const handleSelectThread = (thread) => {
    setSelectedThread(thread);
    setIsAutoResponseEnabled(thread.autoResponseEnabled);
    setScrollOnLoad(true);
    setIsUserNearBottom(true);

    setUnreadThreadIds((prevIds) =>
      prevIds.filter((id) => id !== thread.threadId)
    );
  };

  const handleSelectAgent = (agent) => {
    setSelectedAgent(agent);
    navigate(`/threads/${agent.id}`);
  };

  const handleSendMessage = async () => {
    if (remainingConversations <= 0) {
      setIsLimitReached(true);
      setShowConversationLimitModal(true);
      return;
    }

    if (newMessage.trim() === '') return;

    const messageToSend = newMessage;

    const updatedThread = {
      ...selectedThread,
      messageHistory: [
        ...selectedThread.messageHistory,
        {
          role: 'assistant',
          content: messageToSend,
          createdAt: new Date().toISOString(),
        },
      ],
    };

    setSelectedThread(updatedThread);
    setNewMessage('');

    try {
      const response = await fetch(
        'https://backend.thethingapp.com/send-message',
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            phoneNumber: selectedThread.phoneNumber,
            message: messageToSend,
            assistantId: selectedAgent.id,
          }),
        }
      );

      const data = await response.json();
      if (!data.success) {
        console.error('Error al enviar mensaje:', data.error);
      }
    } catch (error) {
      console.error('Error al conectar con el backend:', error);
    }
  };

  const toggleAutoResponse = async () => {
    if (remainingConversations <= 0) {
      alert('No te quedan conversaciones. No puedes activar la respuesta automática.');
      return;
    }

    const newAutoResponseEnabled = !isAutoResponseEnabled;
    setIsAutoResponseEnabled(newAutoResponseEnabled);

    if (selectedThread && selectedAgent) {
      try {
        const response = await fetch(
          'https://backend.thethingapp.com/update-thread-status',
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              threadId: selectedThread.threadId,
              autoResponseEnabled: newAutoResponseEnabled,
              assistantId: selectedAgent.id,
            }),
          }
        );

        const data = await response.json();
        if (data.success) {
         
        } else {
          console.error(
            'Error al actualizar el estado de respuesta automática:',
            data.error
          );
        }
      } catch (error) {
        console.error('Error al conectar con el backend:', error);
      }
    }
  };

  const formatDate = (dateString) => {
    const options = {
      hour: '2-digit',
      minute: '2-digit',
      day: '2-digit',
      month: '2-digit',
    };
    return new Date(dateString)
      .toLocaleString('es-ES', options)
      .replace(',', '')
      .replace(':', ':');
  };

  const handleDeleteThread = async (threadId) => {
    if (!selectedAgent) {
      alert('No se ha seleccionado un agente.');
      return;
    }

    const confirmDelete = window.confirm(
      '¿Estás seguro de que deseas eliminar este chat? Esta acción no se puede deshacer.'
    );
    if (!confirmDelete) return;

    try {
      await deleteDoc(doc(db, `threads_${selectedAgent.id}`, threadId));
      alert('Chat eliminado con éxito.');

      if (selectedThread && selectedThread.threadId === threadId) {
        setSelectedThread(null);
      }
    } catch (error) {
      console.error('Error al eliminar el chat:', error);
      alert(
        'Hubo un error al eliminar el chat. Por favor, inténtalo de nuevo.'
      );
    }
  };

  return (
    <div className="threads-viewer">
      {loading ? (
        <div
          className="loading"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: 'calc(100vh - 100px)',
            fontSize: 'xx-large',
          }}
        >
          Cargando...
        </div>
      ) : (
        <>
          <div className="threads-list">
            <div className="threads-list-header">
              <div className="pending-switch-container">
                <span>Pendientes</span>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={showOnlyUnread}
                    onChange={() => setShowOnlyUnread(!showOnlyUnread)}
                  />
                  <span className="slider pending-slider"></span>
                </label>
              </div>
              <div
                className="order-filter"
                ref={orderFilterRef}
                onClick={() => setIsOrderDropdownOpen(!isOrderDropdownOpen)}
              >
                <span>Ordenar por</span>
                <FontAwesomeIcon icon={faCaretDown} className="caret-icon" />
                {isOrderDropdownOpen && (
                  <div className="order-dropdown">
                    <div
                      onClick={() => {
                        setOrderBy('name');
                        setIsOrderDropdownOpen(false);
                      }}
                    >
                      Nombre
                    </div>
                    <div
                      onClick={() => {
                        setOrderBy('date');
                        setIsOrderDropdownOpen(false);
                      }}
                    >
                      Fecha de creación
                    </div>
                    <div
                      onClick={() => {
                        setOrderBy('lastMessage');
                        setIsOrderDropdownOpen(false);
                      }}
                    >
                      Fecha de último mensaje
                    </div>
                    <div
                      onClick={() => {
                        setOrderBy('favorites');
                        setIsOrderDropdownOpen(false);
                      }}
                    >
                      Destacado
                    </div>
                  </div>
                )}
              </div>
            </div>

            {selectedAgent ? (
              <ul className="threads-list-items">
                {filteredThreads.map((thread) => (
                  <li
                    key={thread.threadId}
                    className="thread-item"
                    onClick={() => handleSelectThread(thread)}
                  >
                    <div
                      className="favorite-star"
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFavorite(thread.threadId);
                      }}
                    >
                      <FontAwesomeIcon
                        icon={thread.isFavorite ? faStarSolid : faStarRegular}
                        className="star-icon"
                        style={{
                          color: thread.isFavorite ? '#FFD700' : '#CCCCCC',
                          cursor: 'pointer',
                          fontSize: '1.2em',
                        }}
                        aria-label={
                          thread.isFavorite
                            ? 'Quitar favorito'
                            : 'Marcar como favorito'
                        }
                      />
                    </div>

                    <div
                      className="delete-thread"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteThread(thread.threadId);
                      }}
                      title="Eliminar chat"
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="trash-icon"
                        aria-label="Eliminar chat"
                      />
                    </div>

                    <div
                      className="thread-info"
                      style={{ display: 'flex', alignItems: 'start' }}
                    >
                      {thread.phoneNumber ? (
                        <div
                          className="avatar-container"
                          style={{
                            backgroundColor: getPastelColor(
                              thread.senderName || 'Chat Web'
                            ),
                          }}
                        >
                          <span className="avatar-initials">
                            {getInitials(thread.senderName || 'Chat Web')}
                          </span>
                          <FaWhatsapp className="whatsapp-icon" />
                        </div>
                      ) : (
                        <div className="web-chat-avatar-container">
                          <img
                            src={webChatIcon}
                            alt="Web Chat Icon"
                            className="web-chat-icon"
                          />
                        </div>
                      )}
                      <div
                        className="thread-details-preview"
                        style={{ flex: 1, marginLeft: '10px' }}
                      >
                        <p
                          className="thread-sender-name"
                          style={{
                            margin: '0',
                            fontFamily: 'Outfit',
                          }}
                        >
                          {thread.senderName || 'Chat Web'}
                        </p>
                        <div
                          style={{
                            className: 'preview-text-message',
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <p
                            className="thread-last-message thread-preview-text"
                            style={{
                              margin: 0,
                              overflow: 'hidden',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              maxWidth: '18em',
                              color: '#AC9ED4',
                            }}
                          >
                            {thread.messageHistory[
                              thread.messageHistory.length - 1
                            ]?.content || ''}
                          </p>
                          {unreadThreadIds.includes(thread.threadId) && (
                            <span
                              className="unread-dot"
                              style={{
                                marginLeft: '8px',
                                backgroundColor: 'red',
                                borderRadius: '50%',
                                width: '10px',
                                height: '10px',
                                flexShrink: 0,
                              }}
                            ></span>
                          )}
                        </div>
                      </div>
                      <span
                        className="thread-date-info"
                        style={{
                          minWidth: '6em',
                          textAlign: 'right',
                          marginLeft: '10px',
                        }}
                      >
                        {formatDate(
                          thread.messageHistory[
                            thread.messageHistory.length - 1
                          ]?.createdAt
                        )}
                      </span>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <div
                className="no-agent-message"
                style={{
                  flex: 1,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: 'large',
                  textAlign: 'center',
                  padding: '1em',
                }}
              >
                Por favor, selecciona un agente para ver la lista de chats.
              </div>
            )}
          </div>

          {selectedAgent ? (
            selectedThread ? (
              <div
                className="thread-details"
                style={{
                  backgroundImage: `url(${backgroundChat})`,
                  backgroundColor: '#010321',
                  backgroundSize: 'auto',
                  backgroundRepeat: 'repeat',
                  backgroundAttachment: 'fixed',
                  position: 'relative',
                  overflow: 'hidden',
                }}
              >
                <div className="thread-details-content">
                  <div className="thread-info-header">
                    <div className="thread-info-sender">
                      {selectedThread.phoneNumber ? (
                        <div
                          className="avatar-header-container"
                          style={{
                            backgroundColor: getPastelColor(
                              selectedThread.senderName || 'Chat Web'
                            ),
                          }}
                        >
                          <span className="avatar-header-initials">
                            {getInitials(
                              selectedThread.senderName || 'Chat Web'
                            )}
                          </span>
                          <FaWhatsapp className="whatsapp-icon-header" />
                        </div>
                      ) : (
                        <div className="web-chat-avatar-header-container">
                          <img
                            src={webChatIcon}
                            alt="Web Chat Icon"
                            className="web-chat-icon-header"
                          />
                        </div>
                      )}
                      <div className="sender-details">
                        <span className="thread-sender-name">
                          {selectedThread.senderName || 'Chat Web'}
                        </span>
                        <span className="thread-phone-number">
                          {selectedThread.phoneNumber
                            ? formatPhoneNumber(selectedThread.phoneNumber)
                            : 'Website'}
                        </span>
                      </div>
                    </div>

                    <div className="add-contact">
                      <div className="auto-response-container">
                        <div
                          className="tooltip-wrapper"
                          data-tooltip={
                            isAutoResponseEnabled
                              ? 'Autorespuesta IA: ON'
                              : 'Autorespuesta IA:OFF'
                          }
                        >
                          <img
                            src={ControlAgenteIA}
                            alt="Control Agente IA"
                            className="control-agente-ia-icon"
                          />

                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={isAutoResponseEnabled}
                              onChange={toggleAutoResponse}
                            />
                            <span
                              className={`slider-threads ${
                                isAutoResponseEnabled ? 'on' : 'off'
                              }`}
                            ></span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <ul className="message-history" ref={messageHistoryRef}>
                    {selectedThread.messageHistory.map((msg, index) => (
                      <li
                        key={index}
                        className={`message-item ${msg.role}`}
                        style={{
                          display: 'flex',
                          justifyContent:
                            msg.role === 'user' ? 'flex-start' : 'flex-end',
                          marginBottom: '1em',
                        }}
                      >
                        <div
                          className={`message-content ${msg.role}`}
                          style={{
                            borderRadius: '20px',
                            paddingTop: '1.3em',
                            paddingBottom: '1.3em',
                            paddingRight: '2em',
                            paddingLeft: '2em',
                            maxWidth:
                              msg.messageType === 'audio' ? '80%' : '45%',
                            alignSelf:
                              msg.role === 'user' ? 'flex-start' : 'flex-end',
                            width: 'fit-content',
                          }}
                        >
                          {msg.messageType === 'image' ? (
                            <img
                              src={msg.content}
                              alt="Imagen recibida"
                              style={{
                                maxWidth: '100%',
                                borderRadius: '10px',
                              }}
                            />
                          ) : msg.messageType === 'audio' ? (
                            <audio
                              controls
                              style={{ width: '100%', minWidth: '300px' }}
                            >
                              <source src={msg.content} />
                              Tu navegador no soporta el elemento de audio.
                            </audio>
                          ) : (
                            <span
                              className="message-text"
                              dangerouslySetInnerHTML={{
                                __html: formatMessageContent(msg.content),
                              }}
                            />
                          )}
                          <span
                            className="message-timestamp"
                            style={{
                              marginTop: '5px',
                              display: 'block',
                            }}
                          >
                            {formatDate(msg.createdAt)}
                          </span>
                        </div>
                      </li>
                    ))}
                  </ul>

                  <div className="new-message-container">
                    <div className="message-icons">
                      <img
                        src={plusIconChat}
                        alt="Agregar"
                        className="message-icon-one"
                      />
                      <img
                        src={emojiIconChat}
                        alt="Emoji"
                        className="message-icon"
                      />
                      <img
                        src={clipIconChat}
                        alt="Adjuntar"
                        className="message-icon"
                      />
                    </div>
                    <input
                      type="text"
                      className="new-message-input"
                      placeholder="Escribe tu mensaje..."
                      value={newMessage}
                      onChange={(e) => setNewMessage(e.target.value)}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') handleSendMessage();
                      }}
                    />
                    <button
                      className="send-message-button"
                      onClick={handleSendMessage}
                      disabled={remainingConversations <= 0}
                    >
                      <img
                        src={paperClipButton}
                        alt="Enviar"
                        className="send-button-image"
                      />
                    </button>

                  </div>
                </div>
              </div>
            ) : (
              <div className="no-thread-selected" style={{ flex: 1 }}>
              </div>
            )
          ) : (
            <div className="no-agent-selected" style={{ flex: 1 }}></div>
          )}
        </>
      )}

{showConversationLimitModal && (
  <div className="conversation-limit-modal-backdrop">
    <div className="conversation-limit-modal">
      <button
        className="conversation-limit-close-button"
        onClick={() => setShowConversationLimitModal(false)}
      >
        ×
      </button>
      <h2 className="conversation-limit-title">Sin conversaciones disponibles</h2>
      <p className="conversation-limit-text">
        Has agotado tus conversaciones gratuitas. <br />
        Debes comprar más en{' '}
        <a href="https://thethingapp.com" target="_blank" rel="noopener noreferrer">
          thethingapp.com
        </a>. <br />
        No podrás enviar ni recibir nuevos mensajes.
      </p>
      <button
        className="conversation-limit-button"
        onClick={() => window.open('https://thethingapp.com', '_blank', 'noopener,noreferrer')}
      >
        Comprar más conversaciones
      </button>
    </div>
  </div>
)}


    </div>
  );
};

export default ThreadsViewer;
