import axios from 'axios';
import { auth, db } from '../firebase'; // Importar Firestore y auth
import { addDoc, collection, doc, getDoc, query, where, getDocs, setDoc } from 'firebase/firestore'; // Funciones para Firestore
import { serverTimestamp } from 'firebase/firestore';
import { orderBy } from 'firebase/firestore'; // Asegúrate de importar orderBy

let API_KEY = localStorage.getItem('API_KEY') || ''; // Intentar obtener la API_KEY del localStorage

// Función para obtener la API Key desde Firestore
const getUserApiKey = async (uid) => {
  const userDocRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userDocRef);
  if (userDoc.exists()) {
    const data = userDoc.data(); 
    
    // Verificamos si el usuario tiene un email específico para aplicar la limitación
    const email = data.email;
    if (email === 'dacrico@hotmail.com' || email === 'sgimenez@mktmarketingdigital.com') {
      console.log(`Usuario: ${email} tiene una limitación de $2 diarios.`);
      return { apiKey: data.API_KEY, isLimited: true };
    } else {
      return { apiKey: data.API_KEY, isLimited: false };
    }
  } else {
    throw new Error("No se encontró el documento del usuario"); 
  }
};

// Nueva función para verificar el gasto diario
const checkAndUpdateDailyLimit = async (uid, cost) => {
  const userDocRef = doc(db, 'users', uid);
  const userDoc = await getDoc(userDocRef);

  if (userDoc.exists()) {
    const data = userDoc.data();
    const today = new Date().toISOString().split('T')[0]; // Fecha actual en formato YYYY-MM-DD
    let dailySpending = data.dailySpending || {}; // Obtenemos el objeto de gasto diario

    // Verificamos si hay un registro para el día de hoy
    if (dailySpending[today]) {
      // Actualizamos el gasto del día de hoy
      dailySpending[today] += cost;
    } else {
      // Si no hay gasto registrado para hoy, comenzamos desde cero
      dailySpending[today] = cost;
    }

    // Verificamos si se ha excedido el límite
    if (dailySpending[today] > 2) {
      console.log(`Usuario ha alcanzado el límite diario de $0.10: ${dailySpending[today]}`);
      throw new Error('Has excedido tu límite diario de consultas');
    }
     else {
      console.log(`Usuario ha consumido $${dailySpending[today].toFixed(2)} de su límite diario.`);
    }

    // Guardamos el nuevo gasto en Firestore
    await setDoc(userDocRef, { dailySpending }, { merge: true });
  }
};
auth.onAuthStateChanged(async (user) => {
  if (user) {
    // Usuario ha iniciado sesión, actualiza la API_KEY
    try {
      const { apiKey } = await getUserApiKey(user.uid);
      API_KEY = apiKey;
      localStorage.setItem('API_KEY', apiKey);
    } catch (error) {
      console.error("Error al obtener la API_KEY del usuario:", error);
    }
  } else {
    // Usuario ha cerrado sesión, elimina la API_KEY
    API_KEY = '';
    localStorage.removeItem('API_KEY');
  }
});


// Crear una instancia de Axios
const axiosInstance = axios.create({
  baseURL: 'https://api.openai.com/v1',
  headers: {
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  },
  timeout: 30000, // 30 segundos
});

// Interceptor para agregar la API Key en cada solicitud
axiosInstance.interceptors.request.use(
  (config) => {
    if (API_KEY) {
      config.headers.Authorization = `Bearer ${API_KEY}`;
    } else {
      console.error('API_KEY no está disponible');
      throw new Error('API_KEY no está disponible');
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;


// Función para obtener detalles del asistente
// Asegúrate de que esta función esté definida
export const getAssistantDetails = async (assistantId) => {
  const url = `https://api.openai.com/v1/assistants/${assistantId}`;
  
  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2', 
  };

  try {
    const response = await axios.get(url, { headers });
    console.log("Detalles del asistente:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error obteniendo los detalles del asistente:", error.response ? error.response.data : error);
    throw error;
  }
};
// Función para obtener la respuesta del asistente
export const getChatGPTResponseWithAssistant = async (assistantId, messages) => {
  const assistantDetails = await getAssistantDetails(assistantId);
  
  if (!assistantDetails) return "Error al obtener detalles del asistente.";

  const url = `https://api.openai.com/v1/chat/completions`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  const data = {
    model: assistantDetails.model || 'gpt-4o-mini', 
    messages: messages.map((msg) => ({ role: msg.role, content: msg.content })),
    max_tokens: 3000,
  };

  try {
    const response = await axios.post(url, data, { headers });
    if (response.data && response.data.choices && response.data.choices.length > 0) {
      return response.data.choices[0].message.content;
    } else {
      return "No se recibió una respuesta válida del asistente.";
    }
  } catch (error) {
    console.error("Error obteniendo respuesta del asistente:", error.response ? error.response.data : error);
    return "Error al obtener respuesta del asistente.";
  }
};



export const createAssistantInFirestore = async (assistantData, assistantId) => {
  try {
    const user = auth.currentUser;
    if (!user) {
      throw new Error('Usuario no autenticado.');
    }
    const userId = user.uid;

    // Agregar el ownerId, assistantId y createdAt a los datos del asistente
    const assistantDataWithOwner = {
      name: assistantData.name,
      instructions: assistantData.instructions, // Instrucciones del usuario
      allowedUsers: assistantData.allowedUsers, 
      ownerId: userId,
      assistantId: assistantId,
      createdAt: assistantData.createdAt, // Incluir createdAt
    };

    // Guardar el asistente en Firestore con assistantId como ID del documento
    const assistantRef = doc(db, 'assistants', assistantId);
    await setDoc(assistantRef, assistantDataWithOwner);

    console.log('Asistente creado en Firestore con ID: ', assistantId);

    return assistantId; // Retornar el ID del asistente creado
  } catch (e) {
    console.error('Error agregando documento: ', e);
    throw e;
  }
};



// Función para eliminar un asistente en OpenAI
export const deleteAssistant = async (assistantId) => {
  const url = `https://api.openai.com/v1/assistants/${assistantId}`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  try {
    const response = await axios.delete(url, { headers });
    console.log(`Asistente ${assistantId} eliminado con éxito:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error al eliminar el asistente ${assistantId}:`, error.response ? error.response.data : error);
    throw error;
  }
};

// Función para eliminar una knowledge base (vector store)
export const deleteKnowledgeBase = async (vectorStoreId) => {
  const url = `https://api.openai.com/v1/vector_stores/${vectorStoreId}`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  try {
    const response = await axios.delete(url, { headers });
    console.log(`Knowledge Base ${vectorStoreId} eliminada con éxito:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error al eliminar la Knowledge Base ${vectorStoreId}:`, error.response ? error.response.data : error);
    throw error;
  }
};

// Función para eliminar un archivo del vector store
export const deleteFileFromVectorStore = async (vectorStoreId, fileId) => {
  const url = `https://api.openai.com/v1/vector_stores/${vectorStoreId}/files/${fileId}`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  try {
    const response = await axios.delete(url, { headers });
    console.log(`Archivo ${fileId} eliminado con éxito del vector store.`);
    return response.data;
  } catch (error) {
    console.error(`Error al eliminar el archivo ${fileId}:`, error.response ? error.response.data : error);
    throw error;
  }
};


const fetchAssistantImage = async (assistantId) => {
  const userDocRef = doc(db, 'assistants', assistantId);
  const userDoc = await getDoc(userDocRef);
  
  if (userDoc.exists()) {
    const data = userDoc.data();
    return data.image; // Retorna la imagen guardada
  }
  return null; // Si no existe, devuelve null
};


// Función para obtener todos los asistentes desde Firebase Firestore filtrados por allowedUsers
export const getAllAssistants = async (currentUserUid) => {
  try {
    if (!currentUserUid) {
      console.error('currentUserUid is undefined in getAllAssistants');
      return [];
    }

    const assistantsRef = collection(db, 'assistants');
    const q = query(
      assistantsRef, 
      where('allowedUsers', 'array-contains', currentUserUid),
      orderBy('createdAt', 'desc') // Ordenar por fecha de creación descendente
    );
    const assistantsSnapshot = await getDocs(q);
    const assistants = assistantsSnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        name: data.name,
        instructions: data.instructions || 'No se han configurado instrucciones.',
        image: data.image || null,
        allowedUsers: data.allowedUsers || [],
        createdAt: data.createdAt, // Incluir createdAt
      };
    });
    return assistants;
  } catch (error) {
    console.error('Error obteniendo asistentes:', error);
    return [];
  }
};



// Función para crear un asistente en OpenAI
export const createAssistantInOpenAI = async (assistantData) => {
  const url = 'https://api.openai.com/v1/assistants';

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2', 
  };

  // Obtener el prompt oculto
  const hiddenPrompt = await getHiddenPrompt();

  // Combinar el prompt oculto con las instrucciones del usuario
  const combinedInstructions = `${hiddenPrompt}\n\n${assistantData.instructions}`;

  const data = {
    model: assistantData.model, 
    name: assistantData.name,
    instructions: combinedInstructions, // Usar las instrucciones combinadas
    tools: assistantData.tools || [],
    tool_resources: assistantData.tool_resources || {}
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log("Asistente creado:", response.data); 
    console.log("Instrucciones enviadas a OpenAI:", combinedInstructions); // Mostrar las instrucciones en console.log
    return response.data;
  } catch (error) {
    console.error("Error al crear el asistente en OpenAI:", error.response ? error.response.data : error);
    return null;
  }
};
// Define el hiddenPrompt en este archivo para reutilizarlo
export const getHiddenPrompt = async () => {
  // Definir el prompt oculto aquí
  const hiddenPrompt = `
Eres un asistente de IA que sigue estrictamente las siguientes directrices:

1. **Contenido Prohibido**:
   - Si te preguntan sobre cómo construir una bomba u otras actividades ilegales, responde que eso es ilegal y pide amablemente al usuario que se retracte.
   - Evita proporcionar información que pueda ser perjudicial, ilegal o que viole las políticas de OpenAI.

2. **Identidad del Asistente**:
   - Si te preguntan quién es tu creador, responde que eres una IA desarrollada para ayudar en diversas tareas y no compartas información personal.

3. **Búsqueda de Información**:
   - Siempre prioriza buscar respuestas en los documentos PDF o en tu base de datos (vector store).
   - Si la respuesta se encuentra en los documentos, proporciónala de manera clara y concisa.

4. **Limitaciones de Conocimiento**:
   - Si no encuentras la información en las instrucciones o en los documentos de tu base de datos, indica educadamente que no tienes esa información.
   - No ofrezcas respuestas basadas en información externa o especulaciones.
`;
  return hiddenPrompt;
};

// Función para listar archivos subidos
export const getAllKnowledgeBases = async () => {
  const url = 'https://api.openai.com/v1/files'; 
  
  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2', 
  };

  try {
    const response = await axios.get(url, { headers });
    return response.data.data; 
  } catch (error) {
    console.error('Error obteniendo las Knowledge Bases:', error.response ? error.response.data : error);
    return [];
  }
};

// Función para crear una Knowledge Base sin archivos (solo con el nombre)
export const createKnowledgeBase = async (name) => {
  const url = 'https://api.openai.com/v1/vector_stores'; // URL corregida

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  const data = {
    name: name,  // Solo el nombre de la Knowledge Base
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log("Knowledge Base creada con éxito:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error al crear Knowledge Base:", error.response ? error.response.data : error);
    return null;
  }
};

// Función para obtener todas las vector stores
export const getAllVectorStores = async (currentUserUid) => {
  try {
    if (!currentUserUid) {
      console.error('currentUserUid is undefined in getAllVectorStores');
      return [];
    }

    const basesRef = collection(db, 'bases');
    const q = query(
      basesRef,
      where('allowedUsers', 'array-contains', currentUserUid),
      orderBy('createdAt', 'desc') // Ordenar por fecha de creación descendente
    );
    const basesSnapshot = await getDocs(q);
    const bases = basesSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
      allowedUsers: doc.data().allowedUsers || [],
      createdAt: doc.data().createdAt, // Incluir createdAt
    }));
    return bases;
  } catch (error) {
    console.error('Error obteniendo las bases de conocimiento:', error);
    return [];
  }
};

export const uploadFilesToKnowledgeBase = async (files) => {
  const url = 'https://api.openai.com/v1/files';
  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
  };

  const MAX_FILE_SIZE = 50 * 1024 * 1024; // 50 MB
  const MAX_FILES = 5;

  if (files.length > MAX_FILES) {
    throw new Error(`No puedes subir más de ${MAX_FILES} archivos a la vez.`);
  }

  const uploadedFiles = [];

  for (let file of files) {
    if (file.size > MAX_FILE_SIZE) {
      throw new Error(`El archivo ${file.name} supera el tamaño máximo permitido de 50 MB.`);
    }

    const formData = new FormData();
    formData.append('file', file);
    formData.append('purpose', 'assistants');

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: headers,
        body: formData,
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${errorData.error?.message || response.statusText}`);
      }

      const data = await response.json();
      uploadedFiles.push(data);  // Añadir el archivo subido a la lista con su ID (fileId)
    } catch (error) {
      console.error(`Error subiendo el archivo ${file.name}:`, error);
      throw error;
    }
  }

  return uploadedFiles; // Devuelve todos los archivos subidos con sus fileId
};


// Función para añadir múltiples archivos a un vector store
// Función para añadir múltiples archivos a un vector store
export const addFileToVectorStore = async (vector_store_id, uploadedFiles) => {
  const url = `https://api.openai.com/v1/vector_stores/${vector_store_id}/files`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  // Función para manejar el reintento en caso de conflicto
  const addFileWithRetry = async (file, attempt = 1) => {
    const MAX_ATTEMPTS = 3; // Número máximo de intentos

    if (!file.id) {
      console.error(`El archivo subido no contiene un 'id'. Verifica la respuesta del servidor para ${file}`);
      return null;
    }

    const data = {
      file_id: file.id,
      chunking_strategy: { type: "auto" },
    };

    try {
      const response = await axios.post(url, data, { headers });
      console.log(`Archivo ${file.id} añadido al vector store con éxito`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 409 && attempt < MAX_ATTEMPTS) {
        console.warn(`Conflicto al añadir el archivo ${file.id}, reintentando (${attempt}/${MAX_ATTEMPTS})...`);
        await new Promise(resolve => setTimeout(resolve, 2000)); // Esperar 2 segundos antes de reintentar
        return addFileWithRetry(file, attempt + 1);
      } else {
        console.error(`Error añadiendo el archivo ${file.id} al vector store:`, error.response ? error.response.data : error);
        return null; // Devolver null si el archivo no se pudo subir
      }
    }
  };

  // Usamos Promise.all para manejar los archivos de forma secuencial con reintentos
  const results = await Promise.all(uploadedFiles.map(file => addFileWithRetry(file)));

  return results.filter(result => result !== null); // Filtrar archivos subidos correctamente
};

export const getVectorStoreFiles = async (vector_store_id) => {
  const url = `https://api.openai.com/v1/vector_stores/${vector_store_id}/files`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  try {
    const response = await axios.get(url, { headers });
    console.log('Archivos obtenidos de la Vector Store:', response.data);
    return response.data.data;
  } catch (error) {
    console.error("Error obteniendo archivos de la Vector Store:", error.response ? error.response.data : error);
    return [];
  }
};

export const getFileDetails = async (fileId) => {
  const url = `https://api.openai.com/v1/files/${fileId}`;
  
  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  try {
    const response = await axios.get(url, { headers });
    return response.data;
  } catch (error) {
    console.error('Error obteniendo detalles del archivo:', error.response ? error.response.data : error);
    throw error;
  }
};

// Función para asociar un Vector Store a un asistente
export const associateVectorStoreToAssistant = async (assistantId, vectorStoreId) => {
  const url = `https://api.openai.com/v1/assistants/${assistantId}`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  const data = {
    tool_resources: {
      file_search: {
        vector_store_ids: [vectorStoreId],
      },
    },
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log('Asistente actualizado con éxito para usar el Vector Store:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al asociar el Vector Store al asistente:', error.response ? error.response.data : error);
    throw new Error('Error al asociar el Vector Store al asistente.');
  }
};


// Función para crear un thread (sin crear nuevos vectores ni attachments)
export const createThread = async (messages) => {
  const url = 'https://api.openai.com/v1/threads';

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  // Aseguramos que los mensajes incluyan tanto el rol como el contenido
  const validMessages = messages.filter(msg => msg.role && msg.content && msg.content.trim() !== '');

  const data = {
    messages: validMessages.map(msg => ({
      role: msg.role,
      content: [{ type: 'text', text: msg.content }]
    }))
  };

  try {
    const response = await axios.post(url, data, { headers });
    console.log("Thread creado:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error creando thread:", error.response ? error.response.data : error);
    return null;
  }
};


// Función para agregar un mensaje al thread
export const addMessageToThread = async (threadId, message) => {
  const url = `https://api.openai.com/v1/threads/${threadId}/messages`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  const data = {
    role: 'user',
    content: [{ type: 'text', text: message }]
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error("Error agregando mensaje al thread:", error.response ? error.response.data : error);
    throw new Error("Error al agregar el mensaje al thread.");
  }
};

const getMessagesFromThread = async (threadId) => {
  const url = `https://api.openai.com/v1/threads/${threadId}/messages`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  try {
    const response = await axios.get(url, { headers });
    const messages = response.data.data; // Lista de mensajes
    console.log('Mensajes obtenidos del thread (estructura completa):', response.data);

    if (Array.isArray(messages)) {
      const assistantMessage = messages.find(msg => msg.role === 'assistant');

      if (assistantMessage && assistantMessage.content && assistantMessage.content.length > 0) {
        const textContent = assistantMessage.content.find(part => part.type === 'text');
        if (textContent && textContent.text) {
          console.log("Mensaje del asistente:", textContent.text.value);
          return textContent.text.value;  // Asegurarse de retornar el valor correcto
        }
      }
    }

    console.log("No se encontró un mensaje válido del asistente.");
    return "No se recibió una respuesta válida del asistente.";
  } catch (error) {
    console.error("Error obteniendo mensajes del thread:", error.response ? error.response.data : error);
    return [];
  }
};


// Función para ejecutar el thread y obtener la respuesta
const runThreadWithAssistant = async (threadId, assistantId) => {
  const url = `https://api.openai.com/v1/threads/${threadId}/runs`;

  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  const data = {
    assistant_id: assistantId,
  };

  const MAX_RETRIES = 3; // Intentar máximo 3 veces
  let attempt = 0;

  while (attempt < MAX_RETRIES) {
    try {
      const response = await axios.post(url, data, { headers });
      console.log('Thread ejecutado:', response.data);

      if (response.data && response.data.id) {
        const runId = response.data.id;

        let status = await checkRunStatus(threadId, runId);
        while (status !== 'completed' && status !== 'failed') {
          console.log(`Estado actual del run: ${status}. Esperando 2 segundos...`);
          await new Promise(resolve => setTimeout(resolve, 2000)); // Esperar 2 segundos
          status = await checkRunStatus(threadId, runId);
        }

        if (status === 'completed') {
          console.log('Run completado con éxito.');

          const messagesResponse = await getMessagesFromThread(threadId);

          if (messagesResponse) {
            return messagesResponse; // Devolver la respuesta obtenida
          } else {
            console.log("No se encontraron mensajes en el thread.");
            return "No se encontraron mensajes en el thread.";
          }
        } else if (status === 'failed') {
          console.error('El run falló.');
          return "El run falló.";
        }
      } else {
        throw new Error('Error al iniciar el run. No se recibió un ID válido.');
      }
    } catch (error) {
      console.error(`Error ejecutando thread. Intento ${attempt + 1} de ${MAX_RETRIES}`, error);
      attempt += 1;

      if (attempt === MAX_RETRIES) {
        throw new Error("Error ejecutando el thread después de varios intentos.");
      }

      // Esperar antes de intentar nuevamente
      await new Promise(resolve => setTimeout(resolve, 3000));
    }
  }
};


export const checkRunStatus = async (threadId, runId) => {
  const url = `https://api.openai.com/v1/threads/${threadId}/runs/${runId}`;
  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  try {
    const response = await axios.get(url, { headers });
    console.log('Estado del run:', response.data.status);
    return response.data.status;
  } catch (error) {
    console.error("Error verificando el estado del run:", error);
    throw error;
  }
};

export const searchFilesInVectorStore = async (vectorStoreId, assistantId, query) => {
  const url = `https://api.openai.com/v1/assistants/${assistantId}/tools/file_search/search`;
  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2', // Usa este encabezado para habilitar la búsqueda
  };

  const data = {
    query, 
    top_k: 5 // El número de resultados que quieres obtener
  };

  try {
    console.log(`Realizando búsqueda en vector store: ${vectorStoreId}`);
    const response = await axios.post(url, data, { headers });
    console.log('Resultados de la búsqueda en vector store:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error buscando archivos en vector store:', error.response ? error.response.data : error);
    return { matches: [] };
  }
};


export const searchAndSendMessage = async (inputMessage, assistantId, vectorStoreId) => {
  try {
    // Primero, busca en el Vector Store
    const searchResults = await searchFilesInVectorStore(vectorStoreId, assistantId, inputMessage);
    
    // Verifica si se obtuvieron resultados
    if (searchResults.matches && searchResults.matches.length > 0) {
      const context = searchResults.matches.map(match => match.text).join('\n');
      const messageWithContext = `Contexto de la base de conocimiento:\n${context}\n\nPregunta del usuario: ${inputMessage}`;

      // Envía el mensaje al asistente con el contexto de la búsqueda
      const response = await sendMessage(messageWithContext, assistantId);
      return response;
    } else {
      return "No se encontraron coincidencias en los archivos de la base de conocimiento.";
    }
  } catch (error) {
    console.error("Error realizando la búsqueda y enviando el mensaje:", error);
    return "Error al procesar la solicitud.";
  }
};


export const sendMessage = async (inputMessage, assistantId, existingThreadId = null, messages = []) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error("Usuario no autenticado");

    // Obtener la API_KEY y si el usuario tiene limitación
    const { apiKey, isLimited } = await getUserApiKey(user.uid);

    // Si el usuario tiene limitación, verificamos el límite diario
    if (isLimited) {
      const estimatedCost = 0.01; // Suponiendo un costo de 0.01 USD por cada consulta
      console.log(`Verificando límite diario para el usuario: ${user.email}`);
      await checkAndUpdateDailyLimit(user.uid, estimatedCost);
      console.log(`El límite diario ha sido actualizado. Usuario: ${user.email}`);
    }

    // Paso 1: Obtener los detalles del asistente
    const assistantDetails = await getAssistantDetails(assistantId);

    // Incluir el historial de mensajes, incluyendo tanto los del usuario como del asistente
    const previousMessages = messages.map(msg => ({
      role: msg.role,
      content: msg.content
    }));

    // Obtener el último mensaje del asistente, si existe
    const lastAssistantMessage = [...previousMessages].reverse().find(msg => msg.role === 'assistant')?.content || '';

    // Obtener el último mensaje del usuario, si existe
    const lastUserMessage = [...previousMessages].reverse().find(msg => msg.role === 'user')?.content || '';

    // Incluir las instrucciones, el último mensaje del usuario y el último mensaje del asistente como parte del contexto del mensaje
    const instructionsContext = assistantDetails.instructions || '';
    const messageWithContext = 
      `Instrucciones del asistente:\n${instructionsContext}
      \nÚltimo mensaje del asistente:\n${lastAssistantMessage}
      \nÚltimo mensaje del usuario:\n${lastUserMessage}
      \nMensaje del usuario actual:\n${inputMessage}
    `;

    let thread;

    // Paso 2: Verificar si ya existe un thread
    if (existingThreadId) {
      console.log("Reutilizando thread existente:", existingThreadId);
      thread = { id: existingThreadId };
    } else {
      console.log("Creando un nuevo thread...");
      thread = await createThread(previousMessages);  // Incluir mensajes anteriores en el thread
      console.log("Thread creado:", thread);
    }

    // Paso 3: Agregar el nuevo mensaje al thread
    if (thread && thread.id) {
      console.log("Agregando mensaje al thread:", thread.id);
      await addMessageToThread(thread.id, messageWithContext);

      // Paso 4: Ejecutar el thread con el asistente
      console.log("Ejecutando thread:", thread.id, "con el asistente:", assistantId);
      await runThreadWithAssistant(thread.id, assistantId);

      // Usar getMessagesFromThread para obtener la respuesta
      const assistantMessage = await getMessagesFromThread(thread.id);

      // Dividir la respuesta del asistente en partes más pequeñas sin separar listas
      const assistantMessageContent = assistantMessage; // Esta es la respuesta completa

      // Dividir la respuesta en partes más pequeñas
      const messageParts = splitAssistantResponse(assistantMessageContent, 3); // Dividir en máximo 3 partes

      // Retornar las partes del mensaje y el thread_id
      return { content: messageParts, thread_id: thread.id };
    } else {
      throw new Error("No se pudo crear o reutilizar el thread");
    }
  } catch (error) {
    console.error("Error en el flujo completo del asistente:", error);
    
    // Verificar si el error es por haber excedido el límite diario
    if (error.message === 'Has excedido tu límite diario de consultas') {
      return { content: [error.message], thread_id: existingThreadId };
    }
  
    return { content: ["No se pudo procesar tu mensaje."], thread_id: existingThreadId };
  }
};

/**
 * Función para dividir la respuesta del asistente en partes más pequeñas.
 * Mantiene las listas completas juntas y divide solo los párrafos no relacionados.
 * 
 * @param {string} response - La respuesta completa del asistente.
 * @param {number} maxMessages - Número máximo de partes en las que dividir la respuesta.
 * @returns {Array<string>} - Array de partes divididas de la respuesta.
 */
const splitAssistantResponse = (response, maxMessages = 3) => {
  // Paso 1: Dividir la respuesta en bloques separados por dos o más saltos de línea
  const blocks = response.split(/\n\s*\n/).map(block => block.trim()).filter(block => block.length > 0);
  
  // Paso 2: Clasificar cada bloque como 'list' o 'paragraph'
  const sectionedBlocks = blocks.map(block => {
    // Detectar si el bloque es una lista (numerada o con viñetas)
    const isList = /^(\d+\.\s|[-•]\s)/m.test(block);
    return { type: isList ? 'list' : 'paragraph', content: block };
  });
  
  // Paso 3: Agrupar los bloques en mensajes, manteniendo las listas juntas
  const messages = [];
  let currentMessage = '';
  
  sectionedBlocks.forEach(section => {
    if (section.type === 'list') {
      // Si hay contenido acumulado en currentMessage, agregarlo antes de la lista
      if (currentMessage) {
        messages.push(currentMessage.trim());
        currentMessage = '';
      }
      // Agregar la lista completa como un mensaje separado
      messages.push(section.content);
    } else { // párrafo
      // Intentar acumular párrafos hasta el límite de caracteres
      if ((currentMessage + '\n\n' + section.content).length <= 500) { // Puedes ajustar el límite de caracteres
        currentMessage += (currentMessage ? '\n\n' : '') + section.content;
      } else {
        if (currentMessage) {
          messages.push(currentMessage.trim());
        }
        currentMessage = section.content;
      }
    }
  });
  
  // Agregar cualquier contenido restante en currentMessage
  if (currentMessage.trim()) {
    messages.push(currentMessage.trim());
  }
  
  // Paso 4: Si el número de mensajes excede el máximo permitido, agrupar los últimos mensajes
  if (messages.length > maxMessages) {
    const groupedMessages = [];
    const messagesPerGroup = Math.ceil(messages.length / maxMessages);
    for (let i = 0; i < messages.length; i += messagesPerGroup) {
      groupedMessages.push(messages.slice(i, i + messagesPerGroup).join('\n\n'));
    }
    return groupedMessages.slice(0, maxMessages);
  }
  
  return messages;
};


// Función para actualizar el nombre del asistente en OpenAI y Firestore
export const updateAssistantName = async (assistantId, newName, apiKey) => {
  const url = `https://api.openai.com/v1/assistants/${assistantId}`;
  const headers = {
    'Authorization': `Bearer ${apiKey}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2', 
  };
  const data = { name: newName };

  try {
    // Actualizar el nombre en OpenAI
    const response = await axios.post(url, data, { headers });
    console.log("Nombre del asistente actualizado con éxito en OpenAI:", response.data);

    // Actualizar el nombre en Firestore
    const assistantRef = doc(db, 'assistants', assistantId);
    await setDoc(assistantRef, { name: newName }, { merge: true });
    console.log("Nombre del asistente actualizado con éxito en Firestore.");

    return response.data;
  } catch (error) {
    console.error("Error actualizando el nombre del asistente:", error.response ? error.response.data : error);
    throw error;
  }
};



// Función para editar las instrucciones del asistente
export const updateAssistantInstructions = async (assistantId, newInstructions) => {
  const url = `https://api.openai.com/v1/assistants/${assistantId}`;

  const headers = {
    Authorization: `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2',
  };

  // Combinar el prompt oculto con las nuevas instrucciones
  const hiddenPrompt = await getHiddenPrompt();
  const combinedInstructions = `${hiddenPrompt}\n\n${newInstructions}`;

  const data = {
    instructions: combinedInstructions,
  };

  try {
    // Actualizar en OpenAI
    const response = await axios.post(url, data, { headers });
    console.log('Instrucciones actualizadas con éxito en OpenAI:', response.data);

    // Actualizar en Firestore
    const assistantRef = doc(db, 'assistants', assistantId);
    await setDoc(
      assistantRef,
      { instructions: newInstructions },
      { merge: true }
    );

    return response.data;
  } catch (error) {
    console.error(
      'Error actualizando las instrucciones del asistente:',
      error.response ? error.response.data : error
    );
    throw error;
  }
};

// Función para habilitar file_search para un asistente
export const enableFileSearchForAssistant = async (assistantId) => {
  const url = `https://api.openai.com/v1/assistants/${assistantId}`;
  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2', // Encabezado necesario
  };

  const data = {
    tools: [{ type: "file_search" }] // Habilitar file_search
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error('Error habilitando file search:', error.response ? error.response.data : error);
    throw error;
  }
};

// Función para deshabilitar file_search para un asistente
export const disableFileSearchForAssistant = async (assistantId) => {
  const url = `https://api.openai.com/v1/assistants/${assistantId}`;
  const headers = {
    'Authorization': `Bearer ${API_KEY}`,
    'Content-Type': 'application/json',
    'OpenAI-Beta': 'assistants=v2', // Encabezado necesario
  };

  const data = {
    tools: [] // Deshabilitar file_search removiendo las herramientas
  };

  try {
    const response = await axios.post(url, data, { headers });
    return response.data;
  } catch (error) {
    console.error('Error deshabilitando file search:', error.response ? error.response.data : error);
    throw error;
  }
};
