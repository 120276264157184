import React from 'react';
import './SolicitarAcceso.css';
import { useNavigate } from 'react-router-dom';
import solicitudaccesoImage from '../assets/solicituddeacceso.png.png';

const SolicitarAcceso = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/login');
  };

  return (
    <div className="solicitud-enviada">
      <h1>Su solicitud de ingreso ha sido enviada</h1>
      <img src={solicitudaccesoImage} alt="PageSecurity" className="security-image" />
      <p>Pronto se le concederá el acceso</p>
      <button onClick={handleGoHome} className="go-home-button">
        Volver al Inicio
      </button>
    </div>
  );
};

export default SolicitarAcceso;
