// src/context/SubscriptionContext.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import { useAuth } from './AuthContext';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';

const SubscriptionContext = createContext();

export const useSubscription = () => {
  return useContext(SubscriptionContext);
};

export const SubscriptionProvider = ({ children }) => {
  const { currentUser } = useAuth();
  const [isExpired, setIsExpired] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!currentUser) {
      setIsExpired(false);
      setLoading(false);
      return;
    }

    const userRef = doc(db, 'users', currentUser.uid);
    const unsubscribe = onSnapshot(userRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        const accessExpiresAt = data.accessExpiresAt?.toDate();

        if (accessExpiresAt) {
          const today = new Date();
          // Comparar solo fechas sin horas
          const expiryDate = new Date(
            accessExpiresAt.getFullYear(),
            accessExpiresAt.getMonth(),
            accessExpiresAt.getDate()
          );
          const currentDate = new Date(
            today.getFullYear(),
            today.getMonth(),
            today.getDate()
          );

          if (expiryDate < currentDate) {
            setIsExpired(true);
          } else {
            setIsExpired(false);
          }
        } else {
          // Si no hay fecha de vencimiento, consideramos que está expirada
          setIsExpired(true);
        }
      } else {
        // Si el documento no existe, consideramos que está expirada
        setIsExpired(true);
      }
      setLoading(false);
    }, (error) => {
      console.error('Error al obtener datos de suscripción:', error);
      setIsExpired(true);
      setLoading(false);
    });

    return () => unsubscribe();
  }, [currentUser]);

  return (
    <SubscriptionContext.Provider value={{ isExpired, loading }}>
      {children}
    </SubscriptionContext.Provider>
  );
};
