import React from 'react';
import './PrivacyPolicy.css'; // Importa tus estilos si es necesario

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>The Thing Privacy Policy</h1>
      <p><strong>Last Updated: October 22, 2024</strong></p>

      <p>
        At The Thing (“we,” “our,” or “the App”), we are committed to protecting the privacy and security of our users. This Privacy Policy describes how we collect, use, disclose, and safeguard your information when you use our application available at <a className='linki' href="https://platform.thethingapp.com">platform.thethingapp.com</a> (the “App”). By using our App, you agree to the practices described in this policy.
      </p>

      <h2>1. Information We Collect</h2>
      <h3>1.1 Personal Information</h3>
      <p>
        We collect personal information that you provide directly when you register, use our App, or communicate with us. This may include:
      </p>
      <ul>
        <li>Name</li>
        <li>Email address</li>
        <li>Phone number</li>
        <li>Payment information</li>
        <li>Any other information you choose to provide</li>
      </ul>

      <h3>1.2 Usage Information</h3>
      <p>
        We collect information about how you interact with our App, including:
      </p>
      <ul>
        <li>IP addresses</li>
        <li>Browser type</li>
        <li>Pages visited</li>
        <li>Access times</li>
        <li>Location data</li>
      </ul>

      <h3>1.3 Device Information</h3>
      <p>
        We collect information about the devices you use to access our App, such as:
      </p>
      <ul>
        <li>Hardware model</li>
        <li>Operating system</li>
        <li>Unique device identifiers</li>
        <li>Mobile network information</li>
      </ul>

      <h3>1.4 WhatsApp Business Information</h3>
      <p>
        To provide specific functionalities related to WhatsApp Business, we collect and process the following information:
      </p>
      <ul>
        <li>
          <strong>whatsapp_business_messaging Permission:</strong> Used to enable the assistants created in our App to communicate with your customers via WhatsApp, addressing inquiries and providing immediate support through automated responses.
        </li>
        <li>
          <strong>whatsapp_business_management Permission:</strong> Used to facilitate the registration and login of our clients to WhatsApp Business through Facebook’s integrated registration, allowing the association of our App’s assistants with their WhatsApp Business accounts.
        </li>
      </ul>

      <h2>2. Use of Information</h2>
      <p>We use the collected information for the following purposes:</p>
      <ul>
        <li><strong>Provide and Maintain Our App:</strong> Ensuring the App functions correctly and improving its performance.</li>
        <li><strong>Enhance User Experience:</strong> Personalizing and optimizing our users’ experience.</li>
        <li><strong>Develop New Features:</strong> Innovating and adding new features to our App.</li>
        <li><strong>Communicate with You:</strong> Sending notifications, updates, and responding to your inquiries.</li>
        <li><strong>Process Transactions and Manage Payments:</strong> Handling payments and transactions securely.</li>
        <li>
          <strong>Integration with WhatsApp Business:</strong>
          <ul>
            <li>
              <strong>whatsapp_business_messaging:</strong> Allowing automated assistants to interact with your customers via WhatsApp, aggregating all messages from different platforms in one place, functioning as an efficient CRM.
            </li>
            <li>
              <strong>whatsapp_business_management:</strong> Facilitating registration and login to WhatsApp Business, associating the assistants created in our App with your WhatsApp Business accounts to manage conversations and automated responses.
            </li>
          </ul>
        </li>
        <li><strong>Comply with Legal and Regulatory Obligations:</strong> Ensuring compliance with applicable laws and regulations.</li>
      </ul>

      <h2>3. Sharing Information</h2>
      <p>We may share your personal information with third parties under the following circumstances:</p>
      <ul>
        <li><strong>Service Providers:</strong> We share information with providers who perform services on our behalf, such as payment processing, data analysis, and marketing services.</li>
        <li><strong>Legal Compliance:</strong> We may disclose your information if required by law or in response to legal requests.</li>
        <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, your personal information may be transferred as part of the transaction.</li>
      </ul>

      <h2>4. Compliance with Facebook Policies</h2>
      <p>platform.thethingapp.com strictly complies with the privacy and security requirements established by Facebook. Specifically:</p>

      <h3>4.1 Adherence to Facebook’s WhatsApp Business Policies</h3>
      <p>Our App complies with the following Facebook policies to maintain high-quality interactions on the WhatsApp Business Platform:</p>
      <ul>
        <li><strong>Message Policy:</strong> We respect WhatsApp Business’s Message Policy, avoiding the sending of spam and prohibited content.</li>
        <li><strong>Commerce Policy:</strong> We comply with WhatsApp’s Commerce Policy, refraining from selling prohibited products such as alcohol, tobacco, drugs, gambling, and dangerous supplements.</li>
        <li><strong>Service Terms:</strong> We adhere to WhatsApp Business’s Service Terms, avoiding repeated violations that may result in penalties.</li>
      </ul>

      <h3>4.2 Sanctions and Restrictions</h3>
      <p>We understand and respect Facebook’s sanction system, which includes:</p>
      <ul>
        <li><strong>Initial Warnings for Violations:</strong> Early notifications regarding any policy breaches.</li>
        <li><strong>Temporary Restrictions:</strong> Limitations on sending messages and adding phone numbers for periods ranging from 1 to 7 days, depending on the severity of the violation.</li>
        <li><strong>Indefinite Blocks:</strong> Penalties for an indefinite period that can be appealed.</li>
        <li><strong>Account Deactivation:</strong> Deletion of accounts in cases of severe violations such as child exploitation, fraud, terrorism, or the sale of illegal drugs.</li>
      </ul>

      <h3>4.3 Appeals and Violation Review</h3>
      <p>We facilitate the appeal process for any imposed sanctions, allowing businesses to request a review within 90 days following the notification of the violation.</p>

      <h3>4.4 Integration with Webhooks</h3>
      <p>Our App uses webhooks to receive real-time notifications about changes to the WhatsApp Business account, ensuring a swift response to prevent future violations.</p>

      <h2>5. Information Security</h2>
      <p>We implement technical and organizational security measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction. These measures include:</p>
      <ul>
        <li><strong>Data Encryption:</strong> Protecting information using advanced encryption techniques.</li>
        <li><strong>Role-Based Access Control:</strong> Restricting access to information only to authorized personnel.</li>
        <li><strong>Continuous System Monitoring:</strong> Constantly monitoring to detect and prevent potential threats.</li>
        <li><strong>Regular Security Assessments:</strong> Periodic audits to ensure the integrity of our security measures.</li>
      </ul>

      <h2>6. User Rights</h2>
      <p>In accordance with the current legislation in Argentina, you have the following rights regarding your personal information:</p>
      <ul>
        <li><strong>Access:</strong> Request access to your personal data that we have stored.</li>
        <li><strong>Rectification:</strong> Request correction of inaccurate or incomplete data.</li>
        <li><strong>Deletion:</strong> Request the deletion of your personal data under certain circumstances.</li>
        <li><strong>Objection:</strong> Object to the processing of your data for specific purposes.</li>
        <li><strong>Portability:</strong> Request the transfer of your data to another service provider.</li>
      </ul>
      <p>To exercise these rights, please contact us using the information provided in the Contact section.</p>

      <h2>7. International Data Transfers</h2>
      <p>Your information may be transferred and stored on servers located outside of Argentina. We ensure that such transfers comply with applicable data protection laws and that your information is adequately protected.</p>

      <h2>8. Data Retention</h2>
      <p>We retain your personal information only for as long as necessary to fulfill the purposes for which it was collected, including to comply with legal obligations, resolve disputes, and enforce our agreements.</p>

      <h2>9. Changes to the Privacy Policy</h2>
      <p>We may update this Privacy Policy periodically to reflect changes in our practices or applicable legislation. We will notify you of any material changes by posting the new policy on our App. We recommend reviewing this policy regularly to stay informed about how we protect your information.</p>

      <h2>10. Contact</h2>
      <p>If you have any questions, concerns, or requests related to this Privacy Policy, you may contact us at:</p>
      <p>
        <strong>Email:</strong> <a className='linki' href="mailto:info@thethingapp.com">info@thethingapp.com</a><br />
        <strong>Address:</strong> Av. Pres. Roque Saenz Peña 938, Floor 9, Buenos Aires, Argentina.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
