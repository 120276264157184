import React, { createContext, useState, useEffect } from 'react';

export const ThemeContext = createContext();

export const ThemeProvider = ({ children }) => {
  // Cargar isLightMode desde localStorage, por defecto es false si no está definido
  const [isLightMode, setIsLightMode] = useState(() => {
    const savedMode = localStorage.getItem('isLightMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  // Efecto para actualizar localStorage cuando isLightMode cambie
  useEffect(() => {
    localStorage.setItem('isLightMode', JSON.stringify(isLightMode));
  }, [isLightMode]);

  const toggleLightMode = () => {
    setIsLightMode((prevMode) => !prevMode);
  };

  return (
    <ThemeContext.Provider value={{ isLightMode, toggleLightMode }}>
      {children}
    </ThemeContext.Provider>
  );
};
