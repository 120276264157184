import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ThemeContext } from '../context/ThemeContext';
import { AssistantContext } from '../context/AssistantContext';
import arrowLeft from '../assets/arrow-left.png';
import arrowRight from '../assets/arrow-right.png';
import './Header.css';
import {
  doc,
  onSnapshot,
} from 'firebase/firestore';
import { db } from '../firebase';
import bellIcon from '../assets/bell-icon.png';
import { useAuth } from '../context/AuthContext';

const Header = ({ toggleWideSidebar, isWideSidebarVisible }) => {
  const { isLightMode } = useContext(ThemeContext);
  const { assistantName } = useContext(AssistantContext);
  const { currentUser } = useAuth();
  const location = useLocation();

  const [totalThreads, setTotalThreads] = useState(0);
  const [purchasedThreads, setPurchasedThreads] = useState(0);
  const [remainingConversations, setRemainingConversations] = useState(200);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    if (!currentUser) {
      console.warn('Usuario no autenticado');
      return;
    }

    const userDocRef = doc(db, 'users', currentUser.uid);
    const unsubscribeUser = onSnapshot(userDocRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const updatedTotalThreads = userData.totalThreads || 0;
        const updatedPurchasedThreads = userData.purchasedThreads || 0;

        setTotalThreads(updatedTotalThreads);
        setPurchasedThreads(updatedPurchasedThreads);

        const baseFreeConversations = 200;
        const totalAvailable = baseFreeConversations + updatedPurchasedThreads;
        const remaining = Math.max(0, totalAvailable - updatedTotalThreads);
        setRemainingConversations(remaining);

       
      }
    }, (error) => {
      console.error('Error en el listener del documento del usuario:', error);
    });

    return () => {
      unsubscribeUser();
    };
  }, [currentUser]);

  let headerTitle = 'Inicio'; 
  if (location.pathname.startsWith('/threads')) {
    headerTitle = 'Chats';
  } 
  if (location.pathname === '/profile') {
    headerTitle = 'Perfil';
  } 
  if (location.pathname === '/analytics') {
    headerTitle = 'Analíticas';
  } else {
    const chatPathMatch = location.pathname.match(/^\/chat\/([^/]+)/);
    if (chatPathMatch) {
      headerTitle = 'Editor IA';
    }
  }

  let currentAssistantName = 'PAGINA'; 
  if (location.pathname.startsWith('/threads')) { 
    currentAssistantName = 'MIS CHATS';
  }
  if (location.pathname === '/profile') {
    currentAssistantName = 'PERFIL DEL USUARIO';
  } 
  if (location.pathname === '/analytics') {
    currentAssistantName = 'ANALITICAS (PROXIMAMENTE)';
  } else {
    const chatPathMatch = location.pathname.match(/^\/chat\/([^/]+)/);
    if (chatPathMatch) {
      currentAssistantName = assistantName;
    } else if (location.pathname === '/') {
      currentAssistantName = 'MIS AGENTES';
    }
  }

  return (
    <div className="header">
      <div className="header-left">
        <h1 className="header-title">{headerTitle}</h1>
        <button className="toggle-sidebar-button" onClick={toggleWideSidebar}>
          <img
            src={isWideSidebarVisible ? arrowRight : arrowLeft}
            alt={isWideSidebarVisible ? 'Arrow Right' : 'Arrow Left'}
            className="arrow-icon"
          />
        </button>
        <span className="page-title">{currentAssistantName}</span>
      </div>
      <div className="header-right">
        <div className="notification-bell" onClick={() => setIsModalOpen(!isModalOpen)}>
          <img src={bellIcon} alt="Notificaciones" className="bell-icon" />
          <span className="thread-count">{remainingConversations}</span>
        </div>
      </div>

      {isModalOpen && (
        <div className="notification-modal-backdrop" onClick={() => setIsModalOpen(false)}>
          <div className="notification-modal" onClick={(e) => e.stopPropagation()}>
          <h2 className="notification-title">Notificaciones</h2>
            <h3 className="notification-subtitle">Conversaciones Restantes:</h3>
            {remainingConversations <= 0 ? (
              <p className="notification-text">¡Has alcanzado el límite de conversaciones!</p>
            ) : (
              <p className="notification-text">Te quedan {remainingConversations} conversaciones.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
