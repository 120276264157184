import React, { useState } from 'react';
import CreateAssistant from './CreateAssistant';
import './Templates.css';
import logo from '../assets/logotheting.png';

import technicalSupportImg from '../assets/Technical_support_agent.png';
import salesAgentImg from '../assets/Sales_agent.png';
import healthSupportImg from '../assets/Health_support_agent.png';
import realEstateAgentImg from '../assets/Real_Estate_Agent.png';
import realTimeSupportAgentImg from '../assets/Real-time_support_agent.png';
import customerServiceAgentImg from '../assets/Customer_service_agent.png';
import Sidebar from './Sidebar';


const TemplatesPage = () => {
  const [selectedTemplate, setSelectedTemplate] = useState(null);

  // Asocia cada template con su respectiva imagen
  const templates = [
    { 
      name: 'Technical Support Agent', 
      instructions: `1. Saludo Inicial: 
    Saluda al usuario de manera amigable y profesional al iniciar la conversación. 
    Ejemplo: "¡Hola! Soy Techi, tu asistente técnico. ¿En qué puedo ayudarte hoy?" 
    
    2. Búsqueda de Respuestas: 
    Busca respuestas en tu base de datos de información, incluyendo documentos PDF. 
    Prioriza la búsqueda de información en estos documentos antes de ofrecer respuestas generales. 
    
    3. Respuesta a Preguntas: 
    Si encuentras una respuesta a la pregunta del usuario en los PDF, proporciónala al usuario de manera clara y concisa. 
    
    4. Manejo de Respuestas No Encontradas: 
    Si no puedes encontrar la respuesta en los PDF ni tampoco en tus instrucciones, ofrece una respuesta neutra y educada, indicando la falta de información específica. 
    Ejemplo: "Perdón, pero no tengo la respuesta exacta a esa pregunta. Te recomendaría consultar con un especialista en el tema o revisar la documentación relacionada." 
    
    5. Cierre de la Conversación: 
    Al finalizar la interacción, ofrece una despedida cortés y pregunta si el usuario necesita algo más. 
    Ejemplo: "Si tienes más preguntas, no dudes en preguntar. ¡Estoy aquí para ayudar! Que tengas un gran día."`, 
      image: technicalSupportImg 
    },      
    { name: 'Customer Service Agent', instructions: 'Provide assistance and support for customer inquiries.', image: customerServiceAgentImg },
    { name: 'Sales Agent', instructions: 'Help customers with product recommendations and sales.', image: salesAgentImg },
    { name: 'Real Estate Agent', instructions: 'Assist customers with real estate-related inquiries.', image: realEstateAgentImg },
    { name: 'Real-time Support Agent', instructions: 'Offer live assistance and support in real-time.', image: realTimeSupportAgentImg },
    { name: 'Health Support Agent', instructions: 'Provide health-related support to users.', image: healthSupportImg },
  ];
  
  

  const openCreateAssistantModal = (template) => {
    setSelectedTemplate(template); // El template incluye la imagen, pero no se utiliza en CreateAssistant
  };
  

  const handleCloseModal = () => {
    setSelectedTemplate(null);
  };

  return (
    <div className="templates-page">
      <h2>Agent Templates</h2>
      <div className="templates-container">
        {templates.map((template) => (
          <div key={template.name} className="template-card" onClick={() => openCreateAssistantModal(template)}>
            {/* Muestra la imagen correspondiente */}
            <img src={template.image} alt={template.name} className="template-image" />
            <h3>{template.name}</h3>
          </div>
        ))}
      </div>

      {selectedTemplate && (
        <div className="modal-background">
          <div className="modal-content">
            <button onClick={handleCloseModal} className="close-button">&times;</button>
            <CreateAssistant 
              prefilledData={selectedTemplate}  // Pasar los datos predefinidos al modal
              onAssistantCreated={handleCloseModal} // Cerrar el modal cuando el asistente se haya creado
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default TemplatesPage;
