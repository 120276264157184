// src/context/AssistantContext.js

import React, { createContext, useState, useEffect } from 'react';
import { getAllAssistants } from '../services/openaiService'; // Asegúrate de que la ruta es correcta

export const AssistantContext = createContext();

export const AssistantProvider = ({ children }) => {
  const [assistantName, setAssistantName] = useState('');
  const [assistants, setAssistants] = useState([]);
  const [apiKey, setApiKey] = useState(localStorage.getItem('API_KEY') || '');

  useEffect(() => {
    const fetchAssistants = async () => {
      if (apiKey) {
        const fetchedAssistants = await getAllAssistants(apiKey);
        setAssistants(fetchedAssistants);
      }
    };

    fetchAssistants();
  }, [apiKey]);

  return (
    <AssistantContext.Provider value={{ assistantName, setAssistantName, assistants }}>
      {children}
    </AssistantContext.Provider>
  );
};
