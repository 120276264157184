// Import necessary modules and CSS
import React from 'react';
import './PrivacyPolicy.css'; // Import your styles if needed

const ServiceConditions = () => {
  return (
    <div className="service-conditions-container">
      <h1>The Thing Terms of Service</h1>
      <p><strong>Last Updated: October 22, 2024</strong></p>

      <p>
        By accessing and using the application The Thing (“we,” “our,” or “the App”) available at <a className="linki" href="https://platform.thethingapp.com">platform.thethingapp.com</a>, you agree to comply with these Terms of Service (the “Terms”). If you do not agree with any part of these Terms, please do not use the App.
      </p>

      <h2>1. Use of the App</h2>
      <h3>1.1 Eligibility</h3>
      <p>You must be at least 18 years old or of legal age in your jurisdiction to use the App. By using it, you confirm that you meet this requirement and that all information provided is accurate.</p>

      <h3>1.2 Account</h3>
      <p>To access certain functionalities, you may need to create an account. You are responsible for maintaining the confidentiality of your credentials and for all activities that occur under your account.</p>

      <h3>1.3 Usage Restrictions</h3>
      <p>You agree to use the App solely for lawful purposes and in accordance with these Terms. The following is prohibited:</p>
      <ul>
        <li>Using the App for any unlawful or unauthorized purposes.</li>
        <li>Infringing on the intellectual property or privacy rights of others.</li>
        <li>Interfering with the App’s operation or taking actions that could harm it.</li>
      </ul>

      <h2>2. WhatsApp Business Services</h2>
      <p>The App offers specific integrations with WhatsApp Business, including:</p>
      <ul>
        <li>Automated communication with customers via WhatsApp.</li>
        <li>Registration and management of WhatsApp Business accounts for businesses.</li>
      </ul>
      
      <h3>2.1 Compliance with Facebook Policies</h3>
      <p>Use of WhatsApp Business through our App is subject to Facebook’s policies, including but not limited to:</p>
      <ul>
        <li><strong>Message Policy:</strong> Only send relevant and respectful messages to your customers.</li>
        <li><strong>Commerce Policy:</strong> Do not use WhatsApp Business to sell prohibited products, such as drugs or illegal substances.</li>
      </ul>

      <h3>2.2 Sanctions</h3>
      <p>Facebook may impose sanctions in case of policy violations. These may include warnings, temporary restrictions, permanent bans, and account deactivation for severe breaches.</p>

      <h2>3. Payment and Fees</h2>
      <h3>3.1 Subscription Plans</h3>
      <p>Certain services of the App may require a fee. Subscription fees and details are specified within the App and are subject to change.</p>

      <h3>3.2 Cancellation</h3>
      <p>You may cancel your subscription at any time, but payments already made will not be refunded unless required by law.</p>

      <h2>4. Intellectual Property</h2>
      <p>All intellectual property rights of the App and its content, including text, graphics, logos, icons, and software, are owned by The Thing or its licensors.</p>

      <h2>5. Limitation of Liability</h2>
      <p>The App is provided “as is” and “as available.” We do not guarantee it is free from errors or interruptions. You use the App at your own risk.</p>

      <h2>6. Modifications and Updates</h2>
      <p>We may modify, suspend, or discontinue the App or any functionality at any time without notice.</p>

      <h3>6.1 Updates to Terms</h3>
      <p>We reserve the right to modify these Terms at any time. We will notify you of any significant changes by posting the new Terms on the App. By continuing to use the App after any changes, you accept the updated Terms.</p>

      <h2>7. Privacy Policy</h2>
      <p>For details on how we collect, use, and protect your personal information, please review our <a className="linki" href="/privacy-policy">Privacy Policy</a>.</p>

      <h2>8. Governing Law and Jurisdiction</h2>
      <p>These Terms are governed by and construed in accordance with the laws of the Republic of Argentina. Any disputes arising from these Terms will be resolved in the competent courts of Buenos Aires, Argentina.</p>

      <h2>9. Contact</h2>
      <p>If you have any questions or concerns regarding these Terms, please contact us at:</p>
      <p>
        <strong>Email:</strong> <a className="linki" href="mailto:info@thethingapp.com">info@thethingapp.com</a><br />
        <strong>Address:</strong> Av. Pres. Roque Saenz Peña 938, Floor 9, Buenos Aires, Argentina.
      </p>
    </div>
  );
};

export default ServiceConditions;
